import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <main>
         {/* <!-- error area start --> */}
         <section className="tp-error-area pt-110 pb-110">
            <div className="container">
               <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-8 col-md-10">
                     <div className="tp-error-content text-center">
                        <div className="tp-error-thumb">
                           <img src="/assets/img/error/error.png" alt="" />
                        </div>

                        <h3 className="tp-error-title">Oops! Page not found</h3>
                        <p>Whoops, this is embarassing. Looks like the page you were looking for wasn't found.</p>

                        <Link to="/" className="tp-error-btn">Back to Home</Link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {/* <!-- error area end --> */}

      </main>
  )
}

export default NotFound