import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { websendinquiry, webvideolist } from '../../api/apiHandler';
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from 'sweetalert2';
import Spinner from '../../components/Spinner';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

const AllVideos = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen_Product, setIsModlaOpen_Product] = useState(false);
  const [productVideo, setProductVideo] = useState([]);
  const [video, setvideo] = useState(null);
  const [modalData, setModalData] = useState({});
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);

  const validationSchemaForInquiry = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Email address is invalid").required("Email is required"),
    phone:  Yup.string()
    .required('Phone number is required')
    .matches(/^[1-9][0-9]{9}$/, 'Phone number must be exactly 10 digits')
    .length(10, 'Phone number must be exactly 10 digits'),
    messages: Yup.string().required("Message is required"),
  });

  // product inquiry form
  const formOptions1 = { resolver: yupResolver(validationSchemaForInquiry) };
  const { register: registerInquiry, handleSubmit: handleSubmitInquiry, reset: resetInquiry, formState: formStateInquiry } = useForm(formOptions1);
  const { errors: errorsInquiry } = formStateInquiry

  useEffect(() => {
    setIsLoadingProduct(true);
    webvideolist({ id: 0 }).then((response) => {
      if (response.code == 1) {
        setIsLoadingProduct(false);
        setProductVideo(response.data);
      } else {
        setIsLoadingProduct(false);
        setProductVideo([]);
      }
    });
    return () => {
      setIsLoadingProduct(false);
      setProductVideo([]);
    }
  }, [])

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setvideo(null);
  };
  const showProductModal = () => {
    setIsModlaOpen_Product(true);
  };

  const handleCancelProduct = () => {
    setIsModlaOpen_Product(false);
  };

  function validateEmbeddedYouTubeUrl(url) {
    const embeddedYouTubePattern = /^(https?\:\/\/)?(www\.)?youtube\.com\/embed\/.+$/;
    return embeddedYouTubePattern.test(url);
  }

  function handleProductVideo(videoUrl) {
    const isValidEmbeddedYouTubeUrl = validateEmbeddedYouTubeUrl(videoUrl);
    showModal();
    if (isValidEmbeddedYouTubeUrl) {
      setvideo(videoUrl)
    } else {
      setvideo("")
    }
  }

  const onSubmitInquiryForm = (data) => {
    const params = { ...data, product_id: modalData.id }
    websendinquiry(params).then((response) => {
      if (response.code == 1) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        resetInquiry();
        setIsModlaOpen_Product(false);
      } else {
      }
    });
  }


  const buttonProps = {
    style: {
      background: "#06065f",
      borderColor: "white",
      color: "white",
    },
  };
  return (
    <>
      {isModalOpen && (
        <Modal
          title="Singer C440 Legacy Sewing Machine"
          open={isModalOpen}
          className="video_modal"
          width="700px"
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" onClick={handleCancel} {...buttonProps}>
              Ok
            </Button>,
          ]}
        >
          <div>
            {video ? <iframe
              width="100%"
              height="360"
              src={video}
              title="YouTube Video"
              frameBorder="0"
              allowFullScreen
            /> :
              <div>
                <center style={{ padding: "20px" }}><h4>Product video not found.</h4></center>
              </div>
            }
          </div>
        </Modal>
      )}

      {isModalOpen_Product && (
        <Modal
          title={modalData.name}
          open={isModalOpen_Product}
          onCancel={handleCancelProduct}
          width="800px"
          footer={[]}
        >
          <div className="modal_product">
            <div className="modal_product_sub">
              <div className="">
                <img
                  src={modalData.image}
                  width="90%"
                  alt=""
                />
                {/* <p>Rs 70,000 / Piece</p> */}
              </div>

              <div className="modal_product_details">
                <span>Sold by - Sun Maketing</span>
                <br />
                {/* <span> Brand - Singer</span>
                <br />
                <span> Voltage - 110/220v</span>
                <br />
                <span> Power - 55W</span>
                <br />
                <span> One Step Buttonhole - 13</span>
                <br />
                <span>Bobbin Class - 15 Transparent</span> */}
              </div>
            </div>
            <div className="modal_product_sub_form">
              <p>Connect with "Sun Marketing"</p>
              <form onSubmit={handleSubmitInquiry(onSubmitInquiryForm)}>
                <div className="tp-product-details-review-input-wrapper">
                  <div className="tp-product-details-review-input-box">
                    <div className="tp-product-details-review-input">
                      <input
                        name="nameInquiry"
                        id="nameInquiry"
                        type="text"
                        placeholder="Name"
                        {...registerInquiry("name")}
                      />
                    </div>
                    <div className="tp-product-details-review-input-title">
                      <label htmlFor="nameInquiry">Your Name</label>
                    </div>
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errorsInquiry.name?.message}
                    </div>
                  </div>
                  <div className="tp-product-details-review-input-box">
                    <div className="tp-product-details-review-input">
                      <input
                        name="emailInquiry"
                        id="emailInquiry"
                        type="text"
                        placeholder="Name"
                        {...registerInquiry("email")}
                      />
                    </div>
                    <div className="tp-product-details-review-input-title">
                      <label htmlFor="emailInquiry">Your Email</label>
                    </div>
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errorsInquiry.email?.message}
                    </div>
                  </div>
                  <div className="tp-product-details-review-input-box">
                    <div className="tp-product-details-review-input">
                      <input
                        name="phoneInquiry"
                        id="phoneInquiry"
                        type="number"
                        placeholder="Phone"
                        {...registerInquiry("phone")}
                      />
                    </div>
                    <div className="tp-product-details-review-input-title">
                      <label htmlFor="phoneInquiry">Your Phone no</label>
                    </div>
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errorsInquiry.phone?.message}
                    </div>
                  </div>
                  <div className="tp-product-details-review-input-box">
                    <div className="tp-product-details-review-input">
                      <textarea
                        id="msgInquiry"
                        name="msgInquiry"
                        placeholder="Write your review here..."
                        {...registerInquiry("messages")}
                      ></textarea>
                    </div>
                    <div className="tp-product-details-review-input-title">
                      <label htmlFor="msgInquiry">Your Message</label>
                    </div>
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errorsInquiry.messages?.message}
                    </div>
                  </div>
                </div>
                <div className="tp-product-details-review-btn-wrapper">
                  <button className="tp-product-details-review-btn" style={{ borderRadius: '5px' }}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      )}

      <main>
        <section className="tp-shop-area pb-120" style={{ marginTop: "60px" }}>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="tp-shop-main-wrapper">
                  <h3>Product Videos</h3>
                  <div className="tp-shop-items-wrapper tp-shop-item-primary">
                    <div className="tab-content" id="productTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="grid-tab-pane"
                        role="tabpanel"
                        aria-labelledby="grid-tab"
                        tabIndex="0"
                      >
                        <div className="row">
                          {isLoadingProduct && <Spinner />}
                          {(!isLoadingProduct && isEmpty(productVideo)) && <section className="tp-error-area pt-110 pb-110">
                            <div className="container">
                              <div className="row justify-content-center">
                                <div className="col-xl-6 col-lg-8 col-md-10">
                                  <div className="tp-error-content text-center">
                                    <h3 className="tp-error-title">Oops! Videos not found</h3>
                                    <p>Whoops, this is embarassing. Looks like the page you were looking for wasn't found.</p>
                                    <Link to="/" className="tp-error-btn">Back to Home</Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>}
                          {!isEmpty(productVideo) && productVideo.map(val => <div className="col-xl-3 col-lg-3 col-sm-6" key={val.id}>
                            <div className="tp-product-item p-relative transition-3 mb-25">
                              <div
                                className="tp-product-thumb p-relative fix m-img"
                                onClick={() => handleProductVideo(val.video)}
                              >
                                <img
                                  src={`${process.env.REACT_APP_IMAGES}${val.photo[0].image}`}
                                  alt="product-electronic"
                                />
                                <div className="vdo_icn video"></div>
                              </div>
                              {/* <!-- product content --> */}
                              <div className="tp-product-content" style={{height: "130px"}}>
                                <div className="tp-product-category" style={{height: "50px"}}>
                                  {val.name}
                                </div>
                                {/* <h3 className="tp-product-title">
                                  Singer C440 Legacy Sewing Machine
                                </h3> */}
                                <button
                                  className="tp-product-details-buy-now-btn"
                                  style={{ width: "100%", marginTop: "10px" }}
                                  onClick={() => { setModalData({ image: `${process.env.REACT_APP_IMAGES}${val.photo[0].image}`, name: val.name, id: val.product_id }); showProductModal() }}
                                >
                                  Get Best Quote
                                </button>
                              </div>
                            </div>
                          </div>)}

                          {/* <button
                            className="tp-product-details-add-to-cart-btn"
                            style={{ width: "30%", margin: "auto" }}
                          >
                            Watch More Videos
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AllVideos;
