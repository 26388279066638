export const ProductData = [
  {
    id:1,
    image: "/assets/img/product/3/product-1.jpg",
    brandName: "Singer",
    productName: "Singer C440 Legacy Sewing Machine",
    noOfReview: 7,
    price: 320.0,
    discountedPrice: 200,
    description:
      "“Sun Marketing” are a leading Manufacturer and Trader of a wide range of Arun Sewing Machine, Bag Closer Sewing Machine, Brother Sewing Machine, Jack Sewing Machine, etc.",
  },
  {
    id:2,
    image: "/assets/img/product/3/product-2.jpg",
    brandName: "Singer",
    productName: "Singer C440 Legacy Sewing Machine",
    noOfReview: 7,
    price: 320.0,
    discountedPrice: 300,
    description:
      "“Sun Marketing” are a leading Manufacturer and Trader of a wide range of Arun Sewing Machine, Bag Closer Sewing Machine, Brother Sewing Machine, Jack Sewing Machine, etc.",
  },
  {
    id:3,
    image: "/assets/img/product/3/product-3.jpg",
    brandName: "Singer",
    productName: "Singer C440 Legacy Sewing Machine",
    noOfReview: 7,
    price: 320.0,
    discountedPrice: 250,
    description:
      "“Sun Marketing” are a leading Manufacturer and Trader of a wide range of Arun Sewing Machine, Bag Closer Sewing Machine, Brother Sewing Machine, Jack Sewing Machine, etc.",
  },
  {
    id:4,
    image: "/assets/img/product/3/product-1.jpg",
    brandName: "Singer",
    productName: "Singer C440 Legacy Sewing Machine",
    noOfReview: 7,
    price: 320.0,
    discountedPrice: 150,
    description:
      "“Sun Marketing” are a leading Manufacturer and Trader of a wide range of Arun Sewing Machine, Bag Closer Sewing Machine, Brother Sewing Machine, Jack Sewing Machine, etc.",
  },
  {
    id:5,
    image: "/assets/img/product/3/product-2.jpg",
    brandName: "Singer",
    productName: "Singer C440 Legacy Sewing Machine",
    noOfReview: 7,
    price: 320.0,
    discountedPrice: 400,
    description:
      "“Sun Marketing” are a leading Manufacturer and Trader of a wide range of Arun Sewing Machine, Bag Closer Sewing Machine, Brother Sewing Machine, Jack Sewing Machine, etc.",
  },
  {
    id:6,
    image: "/assets/img/product/3/product-3.jpg",
    brandName: "Singer",
    productName: "Singer C440 Legacy Sewing Machine",
    noOfReview: 7,
    price: 320.0,
    discountedPrice: 500,
    description:
      "“Sun Marketing” are a leading Manufacturer and Trader of a wide range of Arun Sewing Machine, Bag Closer Sewing Machine, Brother Sewing Machine, Jack Sewing Machine, etc.",
  },
  {
    id:7,
    image: "/assets/img/product/3/product-1.jpg",
    brandName: "Singer",
    productName: "Singer C440 Legacy Sewing Machine",
    noOfReview: 7,
    price: 320.0,
    discountedPrice: 350,
    description:
      "“Sun Marketing” are a leading Manufacturer and Trader of a wide range of Arun Sewing Machine, Bag Closer Sewing Machine, Brother Sewing Machine, Jack Sewing Machine, etc.",
  },
  {
    id:8,
    image: "/assets/img/product/3/product-2.jpg",
    brandName: "Singer",
    productName: "Singer C440 Legacy Sewing Machine",
    noOfReview: 7,
    price: 320.0,
    discountedPrice: 450,
    description:
      "“Sun Marketing” are a leading Manufacturer and Trader of a wide range of Arun Sewing Machine, Bag Closer Sewing Machine, Brother Sewing Machine, Jack Sewing Machine, etc.",
  },
  {
    id:9,
    image: "/assets/img/product/3/product-3.jpg",
    brandName: "Singer",
    productName: "Singer C440 Legacy Sewing Machine",
    noOfReview: 7,
    price: 320.0,
    discountedPrice: 100,
    description:
      "“Sun Marketing” are a leading Manufacturer and Trader of a wide range of Arun Sewing Machine, Bag Closer Sewing Machine, Brother Sewing Machine, Jack Sewing Machine, etc.",
  },
  {
    id:10,
    image: "/assets/img/product/3/product-1.jpg",
    brandName: "Singer",
    productName: "Singer C440 Legacy Sewing Machine",
    noOfReview: 7,
    price: 320.0,
    discountedPrice: 250,
    description:
      "“Sun Marketing” are a leading Manufacturer and Trader of a wide range of Arun Sewing Machine, Bag Closer Sewing Machine, Brother Sewing Machine, Jack Sewing Machine, etc.",
  },
  {
    id:11,
    image: "/assets/img/product/3/product-2.jpg",
    brandName: "Singer",
    productName: "Singer C440 Legacy Sewing Machine",
    noOfReview: 7,
    price: 320.0,
    discountedPrice: 1000,
    description:
      "“Sun Marketing” are a leading Manufacturer and Trader of a wide range of Arun Sewing Machine, Bag Closer Sewing Machine, Brother Sewing Machine, Jack Sewing Machine, etc.",
  },
];

