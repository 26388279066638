import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { webcategoryproduct, webproductlist, websearchproduct } from '../../api/apiHandler';
import { Rate } from 'antd';
import Spinner from '../../components/Spinner';
import { isEmpty } from 'lodash';
import { Pagination } from 'antd';

const Products = () => {
	const navigate = useNavigate();
	// const { cat_id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [sortBy, setSortBy] = useState("");
	const [sortedData, setSortedData] = useState([]);
	const [defaultData, setDefaultdata] = useState([]);
	const [itemOffset, setItemOffset] = useState(0);
	const [isLoadingProduct, setIsLoadingProduct] = useState(true);
	const [currentPage, setCurrentPage] = useState(0);

	useEffect(() => {
		setIsLoadingProduct(true);
		const search = searchParams.get('search') || ''
		if (search) {
			websearchproduct({ search }).then(response => {
				if (response.code == 1) {
					setIsLoadingProduct(false);
					setSortedData(response.data);
					setDefaultdata(response.data);
				}
				else {
					setIsLoadingProduct(false);
					setSortedData([])
					setDefaultdata([])
				}
			})
		} else {
			webproductlist({ id: 0 }).then(response => {
				if (response.code == 1) {
					setIsLoadingProduct(false);
					setSortedData(response.data);
					setDefaultdata(response.data);
				} else {
					setIsLoadingProduct(false);
					setSortedData([]);
				}
			})
		}

	}, [searchParams]);

	const itemsPerPage = 8;

	const endOffset = itemOffset + itemsPerPage;
	const currentItems = sortedData.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(sortedData.length / itemsPerPage);

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % sortedData.length;
		setItemOffset(newOffset);
		setCurrentPage(event.selected)
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const handleSort = (e) => {
		setSortBy(e.target.value);
		if (e.target.value === "default") {
			setSortedData(defaultData);
			setCurrentPage(0);
		}
		if (e.target.value === "low-to-high") {
			const data = sortedData
				.slice()
				.sort((a, b) => a.offer_price - b.offer_price);
			setSortedData(data);
			setCurrentPage(0);
		}
		if (e.target.value === "high-to-low") {
			const data = sortedData
				.slice()
				.sort((a, b) => b.offer_price - a.offer_price);
			setSortedData(data);
			setCurrentPage(0);
		}
	};

	return (
		<>
			{/* <!-- filter offcanvas area end --> */}

			<main>
				{/* <!-- breadcrumb area start --> */}
				<section className="breadcrumb__area include-bg pt-100 pb-50">
					<div className="container">
						<div className="row">
							<div className="col-xxl-12">
								<div className="breadcrumb__content p-relative z-index-1">
									<h3 className="breadcrumb__title">Shop Grid</h3>
									<div className="breadcrumb__list">
										<span>
											<a href="/">Home</a>
										</span>
										<span>Shop Grid</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <!-- breadcrumb area end --> */}

				{/* <!-- shop area start --> */}
				<section className="tp-shop-area pb-120">
					<div className="container">
						<div className="row">
							<div className="col-xl-12">
								<div className="tp-shop-main-wrapper">
									<div className="tp-shop-top mb-45">
										<div className="row">
											<div className="col-xl-6 col-lg-6 col-md-6">
												<div className="tp-shop-top-left d-flex align-items-center ">
													<div className="tp-shop-top-tab tp-tab">
														<ul
															className="nav nav-tabs"
															id="productTab"
															role="tablist"
														>
															<li className="nav-item" role="presentation">
																<button
																	className="nav-link active"
																	id="grid-tab"
																	data-bs-toggle="tab"
																	data-bs-target="#grid-tab-pane"
																	type="button"
																	role="tab"
																	aria-controls="grid-tab-pane"
																	aria-selected="true"
																>
																	<svg
																		width="18"
																		height="18"
																		viewBox="0 0 18 18"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M16.3327 6.01341V2.98675C16.3327 2.04675 15.906 1.66675 14.846 1.66675H12.1527C11.0927 1.66675 10.666 2.04675 10.666 2.98675V6.00675C10.666 6.95341 11.0927 7.32675 12.1527 7.32675H14.846C15.906 7.33341 16.3327 6.95341 16.3327 6.01341Z"
																			stroke="currentColor"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M16.3327 15.18V12.4867C16.3327 11.4267 15.906 11 14.846 11H12.1527C11.0927 11 10.666 11.4267 10.666 12.4867V15.18C10.666 16.24 11.0927 16.6667 12.1527 16.6667H14.846C15.906 16.6667 16.3327 16.24 16.3327 15.18Z"
																			stroke="currentColor"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.33268 6.01341V2.98675C7.33268 2.04675 6.90602 1.66675 5.84602 1.66675H3.15268C2.09268 1.66675 1.66602 2.04675 1.66602 2.98675V6.00675C1.66602 6.95341 2.09268 7.32675 3.15268 7.32675H5.84602C6.90602 7.33341 7.33268 6.95341 7.33268 6.01341Z"
																			stroke="currentColor"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.33268 15.18V12.4867C7.33268 11.4267 6.90602 11 5.84602 11H3.15268C2.09268 11 1.66602 11.4267 1.66602 12.4867V15.18C1.66602 16.24 2.09268 16.6667 3.15268 16.6667H5.84602C6.90602 16.6667 7.33268 16.24 7.33268 15.18Z"
																			stroke="currentColor"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</button>
															</li>
															<li className="nav-item" role="presentation">
																<button
																	className="nav-link"
																	id="list-tab"
																	data-bs-toggle="tab"
																	data-bs-target="#list-tab-pane"
																	type="button"
																	role="tab"
																	aria-controls="list-tab-pane"
																	aria-selected="false"
																>
																	<svg
																		width="16"
																		height="15"
																		viewBox="0 0 16 15"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M15 7.11108H1"
																			stroke="currentColor"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M15 1H1"
																			stroke="currentColor"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M15 13.2222H1"
																			stroke="currentColor"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</button>
															</li>
														</ul>
													</div>
													<div className="tp-shop-top-result">
														<p>
															Showing {itemOffset}–
															{endOffset > sortedData.length
																? sortedData.length
																: endOffset}{" "}
															of {sortedData.length} results
														</p>
													</div>
												</div>
											</div>
											<div className="col-xl-6 col-lg-6 col-md-6">
												<div className="tp-shop-top-right tp-shop-top-right-2 d-sm-flex align-items-center justify-content-xl-end">
													<div className="tp-shop-top-select">
														<select value={sortBy} onChange={handleSort}>
															<option value="default">Default Sorting</option>
															<option value="low-to-high">Low to Hight</option>
															<option value="high-to-low">High to Low</option>
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="tp-shop-items-wrapper tp-shop-item-primary">
										<div className="tab-content" id="productTabContent">
											<div
												className="tab-pane fade show active"
												id="grid-tab-pane"
												role="tabpanel"
												aria-labelledby="grid-tab"
												tabIndex="0"
											>
												<div className="row">
													{isLoadingProduct && <Spinner />}
													{(!isLoadingProduct && isEmpty(currentItems)) && <section className="tp-error-area pt-110 pb-110">
														<div className="container">
															<div className="row justify-content-center">
																<div className="col-xl-6 col-lg-8 col-md-10">
																	<div className="tp-error-content text-center">
																		<h3 className="tp-error-title">Oops! Product not found</h3>
																		<p>Whoops, this is embarassing. Looks like the page you were looking for wasn't found.</p>
																		<Link to="/" className="tp-error-btn">Back to Home</Link>
																	</div>
																</div>
															</div>
														</div>
													</section>}
													{!isEmpty(currentItems) && currentItems.map((val) => {
														return (
															<div
																className="col-xl-3 col-lg-3 col-sm-6"
																key={val.id}
															>
																<div className="tp-product-item p-relative transition-3 mb-25">
																	<div className="tp-product-thumb p-relative fix m-img">
																		<NavLink to={`/category/${val.category_id}/${val.id}`}>
																			<img
																				src={`${searchParams.get('search') ? process.env.REACT_APP_IMAGES + val.image : process.env.REACT_APP_IMAGES + val.image[0].image}`}
																				alt="product-electronic"
																			/>
																		</NavLink>
																	</div>
																	{/* <!-- product content --> */}
																	<div className="tp-product-content">
																		<div className="tp-product-category">
																			{val.brand}
																		</div>
																		<h3 className="tp-product-title" style={{ height: "50px" }}>
																			{val.name}
																		</h3>
																		<div className="tp-product-rating d-flex align-items-center">
																			<Rate value={val.avg_rating} disabled />
																			<div className="tp-product-rating-text">
																				<span>({val.total_review} Review)</span>
																			</div>
																		</div>
																		<div className="tp-product-price-wrapper">
																			<span className="tp-product-price old-price">
																				Rs.{val.price}
																			</span>
																			<span className="tp-product-price new-price">
																				&nbsp;Rs.{val.offer_price}
																			</span>
																		</div>
																		<button
																			className="tp-product-details-buy-now-btn"
																			style={{ width: "100%", margin: "auto" }}
																			onClick={() =>
																				navigate(`/category/${val.category_id}/${val.id}`)
																			}
																		>
																			View Details
																		</button>
																	</div>
																</div>
															</div>
														);
													})}
												</div>
											</div>
											<div
												className="tab-pane fade"
												id="list-tab-pane"
												role="tabpanel"
												aria-labelledby="list-tab"
												tabIndex="0"
											>
												<div className="tp-shop-list-wrapper tp-shop-item-primary mb-70">
													<div className="row justify-content-center">
														<div className="col-xl-10">
															{currentItems.length > 0 ? currentItems.map((val) => {
																return (
																	<div
																		className="tp-product-list-item d-md-flex"
																		key={val.id}
																	>
																		<div className="tp-product-list-thumb p-relative fix">
																			<NavLink to="/category/category_name/product_name">
																				<img src={`${searchParams.get('search') ? process.env.REACT_APP_IMAGES + val.image : process.env.REACT_APP_IMAGES + val.image[0].image}`} alt="" />
																			</NavLink>
																		</div>
																		<div className="tp-product-list-content">
																			<div className="tp-product-content-2 pt-15">
																				<div className="tp-product-tag-2">
																					{val.brand}
																				</div>
																				<h3 className="tp-product-title-2">
																					{val.name}
																				</h3>
																				<Rate value={val.avg_rating} disabled />
																				<div className="tp-product-rating-text">
																					<span>({val.total_review} Review)</span>
																				</div>
																				<div className="tp-product-price-wrapper-2">
																					<span className="tp-product-price-2 new-price">
																						Rs.{val.offer_price}
																					</span>
																					&nbsp;&nbsp;
																					<span className="tp-product-price-2 old-price">
																						Rs.{val.price}
																					</span>
																				</div>
																				<p>{val.description}</p>
																				<button
																					className="tp-product-details-buy-now-btn"
																					onClick={() =>
																						navigate(`/category/${val.category_id}/${val.id}`)
																					}
																				>
																					View Details
																				</button>
																			</div>
																		</div>
																	</div>
																);
															}) : <section className="tp-error-area pt-110 pb-110">
																<div className="container">
																	<div className="row justify-content-center">
																		<div className="col-xl-6 col-lg-8 col-md-10">
																			<div className="tp-error-content text-center">

																				<h3 className="tp-error-title">Oops! Product not found</h3>
																				<p>Whoops, this is embarassing. Looks like the page you were looking for wasn't found.</p>

																				<Link to="/" className="tp-error-btn">Back to Home</Link>
																			</div>
																		</div>
																	</div>
																</div>
															</section>}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{pageCount > 1 && <div className="product__paginate">
										<ReactPaginate
											breakLabel="..."
											nextLabel=">"
											onPageChange={handlePageClick}
											pageRangeDisplayed={2}
											pageCount={pageCount}
											forcePage={currentPage}
											previousLabel="<"
											renderOnZeroPageCount={null}
										/>
									</div>}

								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <!-- shop area end --> */}
			</main>
		</>
	);
};

export default Products;
