import "./App.css";
import Home from "./pages/home/Home.js";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import { Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Contact from "./pages/contact/Contact";
import ProductsList from "./pages/product/ProductsList";
import ProductDetails from "./pages/product/ProductDetails";
import AllVideos from "./pages/videos/AllVideos";
import Profile from "./pages/profile/Profile";
import NotFound from "./pages/not-found/NotFound";
import AboutUs from "./pages/about/AboutUs";
import Products from './pages/product/Products.js';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products" element={<Products />} />
        <Route exact path="/category/:cat_id" element={<ProductsList />} />
        <Route exact path="/category/:cat_id/:prod_id" element={<ProductDetails />} />
        <Route exact path="/corporate-videos" element={<AllVideos />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/aboutus" element={<AboutUs />} />
        <Route exact path="/*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
      <Footer />
    </>
  );
}

export default App;
