import React from "react";

const Profile = () => {
  return (
    <>
      {/* <!-- breadcrumb area start --> */}
      <section className="breadcrumb__area include-bg text-center pt-95 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content p-relative z-index-1">
                <h3 className="breadcrumb__title">Profile</h3>
                <div className="breadcrumb__list">
                  <span>
                    <a href="/">Home</a>
                  </span>
                  <span>Profile</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tp-contact-area pb-50" style={{ marginTop: "50px" }}>
        <div className="container">
          <div className="tp-contact-inner">
            <center>
              <h2>Factsheet</h2>
            </center>
            <div
              className="tab-pane fade show active"
              id="nav-addInfo"
              role="tabpanel"
              aria-labelledby="nav-addInfo-tab"
              tabIndex="0"
            >
              <div className="tp-product-details-additional-info ">
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <h4>Basic Information</h4>

                    <table>
                      <tbody>
                        <tr>
                          <td>Nature of Business</td>
                          <td>Manufacturer</td>
                        </tr>
                        <tr>
                          <td>Additional Business</td>
                          <td>Trader</td>
                        </tr>
                        <tr>
                          <td>Company CEO</td>
                          <td>Dipan Gohil</td>
                        </tr>
                        <tr>
                          <td>Registered Address</td>
                          <td>
                            Govind Lila Complex, 06,Ground Floor, Patni Sheri,
                            Ghee Kanta Road, Ahmedabad- 380001, Gujarat, India
                          </td>
                        </tr>
                        <tr>
                          <td>Total Number of Employees</td>
                          <td>Upto 10 People</td>
                        </tr>
                        <tr>
                          <td>Year of Establishment</td>
                          <td>2003</td>
                        </tr>
                        <tr>
                          <td>Legal Status of Firm</td>
                          <td>Individual - Proprietor</td>
                        </tr>
                        <tr>
                          <td>Annual Turnover</td>
                          <td>Upto Rs. 50 Lakh</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="tp-product-details-additional-info ">
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <h4>Statutory Profile</h4>
                    <table>
                      <tbody>
                        <tr>
                          <td>Banker</td>
                          <td>Bank of Baroda</td>
                        </tr>
                        <tr>
                          <td>GST No.</td>
                          <td>24AGGPG5235M1ZD</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="tp-product-details-additional-info ">
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <h4>Packaging/Payment and Shipment Details</h4>

                    <table>
                      <tbody>
                        <tr>
                          <td>Payment Mode</td>
                          <td>Cash, Credit Card, DD, Cheque, Bank Transfer</td>
                        </tr>
                        <tr>
                          <td>Shipment Mode</td>
                          <td>By Road</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tp-contact-inner" style={{ marginTop: "60px" }}>
            <center>
              <h2>Why Us?</h2>
            </center>
            <br />
            <p>
              With our expertise and trustworthiness, we have secured a
              respectable position for ourselves in this domain. Different
              factors for which we have attained appreciation from clients
              spread all across the nation are:
            </p>

            <ul>
              <li>Quality assured product range</li>
              <li>Adept team of professionals</li>
              <li>Ethical business policies</li>
              <li>Client-centric approach</li>
              <li>Large distribution network</li>
              <li>Excellent transport & logistic facility</li>
              <li>Reasonable prices</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="tp-contact-area pb-50">
        <div className="container">
          <div className="tp-contact-inner">
            <center>
              <h2>Brands We Deal In:</h2>
            </center>
            <br />
            <p>
              {/* With our expertise and trustworthiness, we have secured a
              respectable position for ourselves in this domain. Different
              factors for which we have attained appreciation from clients
              spread all across the nation are: */}
            </p>

            <ul className='brand_list'>
              <div className='brand_list_sub'>
                <li>Juki</li>
                <li>Jack</li>
                <li>Usha</li>
              </div>
              <div className='brand_list_sub'>
                <li>Singer</li>
                <li>Merrit</li>
                <li>Sun</li>
              </div>
              <div className='brand_list_sub'>
                <li>Nilkanth</li>
                <li>Maqi</li>
                <li>Arun</li>
              </div>
              <div className='brand_list_sub'>
                <li>Brother</li>
                <li>Jemeex</li>
                <li>All accessories</li>
              </div>
            </ul>
          </div>
        </div>
      </section>

      <section className="tp-history-area pb-50" data-bg-color="#F8F8F8">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="tp-history-slider mb-50">
                <div className="tp-history-slider-active ">
                  <div className="swiper-wrapper">
                    <div
                      className="tp-thistory-item swiper-slide"
                      data-bg-color="#F8F8F8"
                      style={{ padding: "30px" }}
                    >
                      <div className="row">
                        <div className="col-xl-5 col-lg-6 col-md-6">
                          <div className="tp-history-wrapper pr-45">
                            <div className="tp-history-content mb-40">
                              <h3 className="tp-history-title">
                                Our Business Philosophy
                              </h3>
                              <p> {"=>"} Company Profile</p>
                              <ul className="profile_page_list">
                                <li>Nature of BusinessManufacturer</li>
                                <li>Total Number of EmployeesUpto 10 People</li>
                                <li>Year of Establishment 2000</li>
                                <li>
                                  Legal Status of FirmIndividual - Proprietor
                                </li>
                                <li>Annual TurnoverUpto Rs. 50 Lakh</li>
                                <li>GST No.24AGGPG5235M1ZD</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6">
                          <div className="tp-history-thumb-wrapper ml-150 p-relative">
                            <div className="tp-history-thumb m-img">
                              <img src="/assets/img/sun.jpg" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      Established as a Sole Proprietorship firm in the year
                      2003, we <b>“Sun Marketing”</b> are a leading Manufacturer and
                      Wholesale Trader of a wide range of Arun Sewing Machine,
                      Bag Closer Sewing Machine, Brother Sewing Machine, Jack
                      Sewing Machine, etc. Situated in Ahmedabad (Gujarat,
                      India), we have constructed a wide and well functional
                      infrastructural unit that plays an important role in the
                      growth of our company. We offer these products at
                      reasonable rates and deliver these within the promised
                      time-frame. Under the headship of our mentor “Mr. Dipan
                      Gohil”, we have gained a huge clientele across the nation.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* <!-- history area end --> */}
    </>
  );
};

export default Profile;
