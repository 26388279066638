import React, { useEffect } from 'react'
import { websociallink } from '../../api/apiHandler'
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const Footer = () => {
   const [socialLinks, setSocialLinks] = useState()

   useEffect(() => {
      websociallink({}).then((response) => {
         if (response.code == 1) {
            setSocialLinks(response.data[0]);
         } else {
            //   setCategoryList([]);
         }
      });
   }, []);

   return (
      <footer>
         <div className="tp-footer-area" data-bg-color="footer-bg-grey">
            <div className="tp-footer-top pt-95 pb-40">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-6 col-lg-6 col-md-6 col-sm-8">
                        <div className="tp-footer-widget footer-col-1 mb-50">
                           <div className="tp-footer-widget-content">
                              <div className="tp-footer-logo">
                                 <Link to="/">
                                    <img src="/assets/img/logo/logo.png" alt="logo" />
                                 </Link>
                              </div>
                              <p className="tp-footer-desc">Sun Marketing” are a leading Manufacturer and Wholesale Trader of a wide range of Arun
                                 Sewing Machine, Bag Closer Sewing Machine, Brother Sewing Machine, Jack Sewing Machine, etc.
                                 Situated in Ahmedabad (Gujarat, India), we have constructed a wide and well functional
                                 infrastructural unit that plays an important role in the growth of our company.</p>
                              <div className="tp-footer-social">
                                 <a href={socialLinks?.facebook && socialLinks.facebook} target='_blank' rel="noreferrer"><i className="fa-brands fa-facebook-f"></i></a>
                                 <a href={socialLinks?.twitter && socialLinks.twitter} target='_blank' rel="noreferrer"><i className="fa-brands fa-twitter"></i></a>
                                 <a href={socialLinks?.linkedin && socialLinks.linkedin} target='_blank' rel="noreferrer"><i className="fa-brands fa-youtube"></i></a>
                                 <a href={socialLinks?.instagram && socialLinks.instagram} target='_blank' rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="tp-footer-widget footer-col-2 mb-50">
                           {/* <h4 className="tp-footer-widget-title">My Account</h4> */}
                           <div className="tp-footer-widget-content">
                              <ul>
                                 <li><NavLink to="/">Home</NavLink></li>
                                 {/* <li><NavLink to="/category">Our Product Range</NavLink></li> */}
                                 <li><NavLink to="/contact"> Contact</NavLink></li>
                                 <li><NavLink to="/profile">Profile</NavLink></li>
                                 <li><NavLink to="/aboutus">About Us</NavLink></li>
                                 <li>
                                    <NavLink to="/corporate-videos">corporate-videos</NavLink>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                        <div className="tp-footer-widget footer-col-4 mb-50">
                           <h4 className="tp-footer-widget-title">Talk To Us</h4>
                           <div className="tp-footer-widget-content">
                              <div className="tp-footer-talk mb-20">
                                 <span>Got Questions? Call us</span>
                                 <h4>(+91) 9173060081</h4>
                              </div>
                              <div className="tp-footer-contact">
                                 <div className="tp-footer-contact-item d-flex align-items-start">
                                    <div className="tp-footer-contact-icon">
                                       <span>
                                          <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M1 5C1 2.2 2.6 1 5 1H13C15.4 1 17 2.2 17 5V10.6C17 13.4 15.4 14.6 13 14.6H5" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                             <path d="M13 5.40039L10.496 7.40039C9.672 8.05639 8.32 8.05639 7.496 7.40039L5 5.40039" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                             <path d="M1 11.4004H5.8" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                             <path d="M1 8.19922H3.4" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                          </svg>
                                       </span>
                                    </div>
                                    <div className="tp-footer-contact-content">
                                       <p>info@sunmarketing.org</p>
                                    </div>
                                 </div>
                                 <div className="tp-footer-contact-item d-flex align-items-start">
                                    <div className="tp-footer-contact-icon">
                                       <span>
                                          <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M8.50001 10.9417C9.99877 10.9417 11.2138 9.72668 11.2138 8.22791C11.2138 6.72915 9.99877 5.51416 8.50001 5.51416C7.00124 5.51416 5.78625 6.72915 5.78625 8.22791C5.78625 9.72668 7.00124 10.9417 8.50001 10.9417Z" stroke="currentColor" strokeWidth="1.5"></path>
                                             <path d="M1.21115 6.64496C2.92464 -0.887449 14.0841 -0.878751 15.7889 6.65366C16.7891 11.0722 14.0406 14.8123 11.6313 17.126C9.88298 18.8134 7.11704 18.8134 5.36006 17.126C2.95943 14.8123 0.210885 11.0635 1.21115 6.64496Z" stroke="currentColor" strokeWidth="1.5"></path>
                                          </svg>
                                       </span>
                                    </div>
                                    <div className="tp-footer-contact-content">
                                       <p>A-06 Parkash Cinema, Govind Lila Complex Patni Sheri, Gheekata, Ahmedabad - 380001, Gujarat, India.</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="tp-footer-bottom">
               <div className="container">
                  <div className="tp-footer-bottom-wrapper">
                     <div className="row align-items-center">
                        <div className="col-md-12">
                           <div className="tp-footer-copyright text-center">
                              <p>© {new Date().getFullYear()} All Rights Reserved  | Sun Marketing.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   )
}

export default Footer