import React, { useEffect, useState } from 'react'
import { getaboutus } from '../../api/apiHandler';

const AboutUs = () => {
	const [aboutUs, setAboutUs] = useState("");


	useEffect(() => {
		getaboutus({id:0}).then((response) => {
			if (response.code == 1) {
				setAboutUs(response.data[0].content);
			} else {
				setAboutUs("");
			}
		});
	}, []);
	return (
		<>
			{/* <!-- breadcrumb area start --> */}
			<section className="breadcrumb__area include-bg text-center pt-95 pb-50">
				<div className="container">
					<div className="row">
						<div className="col-xxl-12">
							<div className="breadcrumb__content p-relative z-index-1">
								<h3 className="breadcrumb__title">About Us</h3>
								<div className="breadcrumb__list">
									<span>
										<a href="/">Home</a>
									</span>
									<span>About Us</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="tp-history-area pb-50 " data-bg-color="#F8F8F8">
				<div className="container tp-contact-inner">
					<div className="row">
						<div className="col-xl-12">
							<div className="tp-history-slider mb-50">
								<div className="tp-history-slider-active ">
									<div className="swiper-wrapper">
										<div
											className="tp-thistory-item swiper-slide"
											data-bg-color="#F8F8F8"
											style={{ padding: "30px" }}
										>
											<div className="row">
												<div className="col-xl-5 col-lg-6 col-md-6">
													<div className="tp-history-wrapper pr-45" >
														{aboutUs}
													</div>
												</div>
												<div className="col-xl-7 col-lg-6 col-md-6">
													<div className="tp-history-thumb-wrapper ml-150 p-relative">
														<div className="tp-history-thumb m-img">
															<img src="/assets/img/sun.jpg" alt="" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

		</>
	)
}

export default AboutUs