import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { webcategorylist } from "../../api/apiHandler";

const Header = () => {
  const navigate = useNavigate();
  const [scrollY, setScrollY] = useState(0);
  const [toggleNav, setToggleNav] = useState(false);
  const [toggleProducts, setToggeleProducts] = useState(false);
  const [categoryList, setCategoryList] = useState();
  const [search, setSearch] = useState("");

  const handleScroll = () => {
    const scrollPosition = window.scrollY || window.pageYOffset;
    setScrollY(scrollPosition);
  };

  useEffect(() => {
    webcategorylist({ id: 0 }).then((response) => {
      if (response.code == 1) {
        setCategoryList(response.data);
      } else {
        setCategoryList([]);
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollY]);

  const handleNav = () => {
    setToggleNav((prev) => !prev);
  };

  const handleToggleProducts = () => {
    setToggeleProducts((prev) => !prev);
  };

  return (
    <>
      {/* <!-- offcanvas area start --> */}
      <div
        className={
          toggleNav
            ? "offcanvas__area offcanvas__radius offcanvas-opened"
            : "offcanvas__area offcanvas__radius"
        }
      >
        <div className="offcanvas__wrapper">
          <div className="offcanvas__close">
            <button
              className="offcanvas__close-btn offcanvas-close-btn"
              onClick={handleNav}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 1L1 11"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 1L11 11"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="offcanvas__content">
            <div className="offcanvas__top mb-70 d-flex justify-content-between align-items-center">
              <div className="offcanvas__logo logo">
                <NavLink to="index.html">
                  <img src="/assets/img/logo/logo.png" alt="logo" />
                </NavLink>
              </div>
            </div>

            <div className="tp-main-menu-mobile fix d-lg-none mb-40">
              <nav className="tp-main-menu-content">
                <ul className="text-end">
                  <li>
                    <div className="col-md-12 col-sm-12">
                      <div className="tp-header-bottom-right d-flex align-items-center justify-content-end">
                        <div className="tp-header-search-2 d-sm-block">
                          {/* <form> */}
                          <input
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search For Products..."
                          />
                          <button onClick={() => navigate(`/products?search=${search}`)}>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M18.9999 19L14.6499 14.65"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </li>
                  
                  <li
                    className=""
                    onClick={() => setToggleNav((prev) => !prev)}
                  >
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li className="has-dropdown has-mega-menu dropdown-opened">
                    <NavLink className={toggleProducts ? "expanded" : ""} >
                      Our Product Range
                      <button
                        className={
                          toggleProducts
                            ? "dropdown-toggle-btn dropdown-opened"
                            : "dropdown-toggle-btn"
                        }
                        onClick={handleToggleProducts}
                      >
                        <i className="fa-regular fa-angle-right"></i>
                      </button>
                    </NavLink>
                    <ul
                      className="tp-submenu tp-mega-menu mega-menu-style-2"
                      style={
                        toggleProducts
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      {/* <!-- first col --> */}
                      <li className="has-dropdown">
                        {categoryList && categoryList.map((val) =>
                          <NavLink
                            to={`/category/${val.id}`}
                            onClick={handleNav}
                            key={val.id}
                          >
                            {val.category_name}
                          </NavLink>
                        )}
                      </li>
                    </ul>
                  </li>
                  <li onClick={() => setToggleNav((prev) => !prev)}>
                    <NavLink to="/contact">Contact</NavLink>
                  </li>
                  <li onClick={() => setToggleNav((prev) => !prev)}>
                    <NavLink to="/profile">Profile</NavLink>
                  </li>
                  <li onClick={() => setToggleNav((prev) => !prev)}>
                    <NavLink to="/aboutus">About Us</NavLink>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="offcanvas__contact align-items-center d-none">
              <div className="offcanvas__contact-icon mr-20">
                <span>
                  <img src="assets/img/icon/contact.png" alt="" />
                </span>
              </div>
              <div className="offcanvas__contact-content">
                <h3 className="offcanvas__contact-title">
                  <NavLink>004524865</NavLink>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          toggleNav ? "body-overlay closed opened" : "body-overlay closed"
        }
        onClick={handleNav}
      ></div>
      {/* <!-- offcanvas area end --> */}
      <header>
        <div className="tp-header-area tp-header-style-darkRed tp-header-height">
          {/* <!-- header top start  --> */}
          <div className="tp-header-top-2 p-relative z-index-11 tp-header-top-border d-none d-md-block">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-6">
                  <div className="tp-header-info d-flex align-items-center">
                    <div className="tp-header-info-item">
                      <NavLink to="/">
                        <span>
                          <svg
                            width="18"
                            height="16"
                            viewBox="0 0 18 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 5C1 2.2 2.6 1 5 1H13C15.4 1 17 2.2 17 5V10.6C17 13.4 15.4 14.6 13 14.6H5"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M13 5.40039L10.496 7.40039C9.672 8.05639 8.32 8.05639 7.496 7.40039L5 5.40039"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M1 11.4004H5.8"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M1 8.19922H3.4"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </span>{" "}
                        info@sunmarketing.org
                      </NavLink>
                    </div>
                    <div className="tp-header-info-item">
                      <NavLink>
                        <span>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.359 2.73916C1.59079 2.35465 2.86862 0.958795 3.7792 1.00093C4.05162 1.02426 4.29244 1.1883 4.4881 1.37943H4.48885C4.93737 1.81888 6.22423 3.47735 6.29648 3.8265C6.47483 4.68282 5.45362 5.17645 5.76593 6.03954C6.56213 7.98771 7.93402 9.35948 9.88313 10.1549C10.7455 10.4679 11.2392 9.44752 12.0956 9.62511C12.4448 9.6981 14.1042 10.9841 14.5429 11.4333V11.4333C14.7333 11.6282 14.8989 11.8698 14.9214 12.1422C14.9553 13.1016 13.4728 14.3966 13.1838 14.5621C12.502 15.0505 11.6125 15.0415 10.5281 14.5373C7.50206 13.2784 2.66618 8.53401 1.38384 5.39391C0.893174 4.31561 0.860062 3.42016 1.359 2.73916Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.84082 1.18318C12.5534 1.48434 14.6952 3.62393 15 6.3358"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.84082 3.77927C11.1378 4.03207 12.1511 5.04544 12.4039 6.34239"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>{" "}
                        (+91) 9173060881
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="tp-header-top-right tp-header-top-black d-flex align-items-center justify-content-end">
                    <div className="tp-header-top-menu d-flex align-items-center justify-content-end">
                      <div className="tp-header-top-menu-item tp-header-setting">
                        <NavLink to="/">GST No. 24AGGPG5235M1ZD</NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- header bottom start --> */}
          <div
            id="header-sticky"
            className={
              scrollY < 100
                ? "tp-header-bottom-2 tp-header-sticky"
                : "tp-header-bottom-2 tp-header-sticky header-sticky"
            }
          >
            <div className="container">
              <div className="tp-mega-menu-wrapper p-relative">
                <div className="row align-items-center">
                  <div className="col-xl-2 col-lg-4 col-md-5 col-sm-4">
                    <div className="logo">
                      <NavLink to="/">
                        <img src="/assets/img/logo/logo.png" alt="logo" />
                      </NavLink>
                    </div>
                    <div className="tp-mobile-item text-center">
                      <button
                        className="tp-mobile-item-btn tp-offcanvas-open-btn"
                        onClick={handleNav}
                      >
                        <i className="flaticon-menu-1"></i>
                        <span>Menu</span>
                      </button>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-4 d-none d-lg-block">
                    <div className="main-menu menu-style-2">
                      <nav className="tp-main-menu-content">
                        <ul className="text-end">
                          <li className="">
                            <NavLink to="/" activeclassname="active__Class">
                              Home
                            </NavLink>
                          </li>

                          <li className="has-dropdown has-mega-menu ">
                            <NavLink>Our Product Range</NavLink>
                            <ul className="tp-submenu">
                              {categoryList && categoryList.map((val) =>
                                <li key={val.id} style={{ marginBottom: "3px" }}>
                                  <NavLink to={`/category/${val.id}`} >
                                    {val.category_name}
                                  </NavLink>
                                </li>
                              )}
                            </ul>
                          </li>

                          <li>
                            <NavLink
                              to="/contact"
                              activeclassname="active__Class"
                            >
                              Contact
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/profile"
                              activeclassname="active__Class"
                            >
                              Profile
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/aboutus"
                              activeclassname="active__Class"
                            >
                              About Us
                            </NavLink>
                          </li>
                        </ul>
                      </nav>
                    </div>

                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-7 col-sm-8 col-6">
                    <div className="tp-header-bottom-right d-flex align-items-center justify-content-end pl-30">
                      <div className="tp-header-search-2 d-none d-sm-block">
                        {/* <form> */}
                        <input
                          type="text"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search For Products..."
                        />
                        <button onClick={() => navigate(`/products?search=${search}`)}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18.9999 19L14.6499 14.65"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        {/* </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
