import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { getFaqs, webContactUs, websociallink } from "../../api/apiHandler";
import { Accordion } from "@mui/material";
import { AccordionSummary } from "@mui/material";
import { AccordionDetails } from "@mui/material";
import { Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

const Contact = () => {
    const navigate = useNavigate();
    const [faqsList, setFaqsList] = useState(null);
    const [socialLinks, setSocialLinks] = useState()

    useEffect(() => {
        websociallink({}).then((response) => {
            if (response.code == 1) {
                setSocialLinks(response.data[0]);
            } else {
                //   setCategoryList([]);
            }
        });
    }, []);

    useEffect(() => {
        websociallink({}).then((response) => {
            if (response.code == 1) {
                setSocialLinks(response.data[0]);
            } else {
                //   setCategoryList([]);
            }
        });
    }, []);

    useEffect(() => {
        getFaqs({ id: 0 }).then((response) => {
            if (response.code == 1) {
                setFaqsList(response.data);
            } else {
                setFaqsList([]);
            }
        });
    }, []);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().email("Email address is invalid").required("Email is required"),
        phone: Yup.string()
            .required('Phone number is required')
            .matches(/^[1-9][0-9]{9}$/, 'Phone number must be exactly 10 digits')
            .length(10, 'Phone number must be exactly 10 digits'),
        subject: Yup.string().required("Subject is required"),
        messages: Yup.string().required("Message is required"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
        webContactUs(data).then((response) => {
            if (response.code == 1) {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000,
                });
                reset();
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            } else {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        });
    };
    return (
        <>


            {/* <!-- breadcrumb area start --> */}
            <section className="breadcrumb__area include-bg text-center pt-95 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="breadcrumb__content p-relative z-index-1">
                                <h3 className="breadcrumb__title">Keep In Touch with Us</h3>
                                <div className="breadcrumb__list">
                                    <span>
                                        <a href="/">Home</a>
                                    </span>
                                    <span>Contact</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tp-contact-area pb-100">
                <div className="container">
                    <div className="tp-contact-inner">
                        <div className="row">
                            <div className="col-xl-9 col-lg-8">
                                <div className="tp-contact-wrapper">
                                    <h3 className="tp-contact-title">Sent A Message</h3>

                                    <div className="tp-contact-form">
                                        <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="tp-contact-input-wrapper">
                                                <div className="tp-contact-input-box">
                                                    <div className="tp-contact-input">
                                                        <input name="name" id="name" type="text" placeholder="Name" {...register("name")} />
                                                    </div>
                                                    <div className="tp-contact-input-title">
                                                        <label htmlFor="name">Your Name</label>
                                                    </div>
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.name?.message}
                                                    </div>
                                                </div>
                                                <div className="tp-contact-input-box">
                                                    <div className="tp-contact-input">
                                                        <input name="email" id="email" type="text" placeholder="Email" {...register("email")} />
                                                    </div>
                                                    <div className="tp-contact-input-title">
                                                        <label htmlFor="email">Your Email</label>
                                                    </div>
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.email?.message}
                                                    </div>
                                                </div>
                                                <div className="tp-contact-input-box">
                                                    <div className="tp-contact-input">
                                                        <input name="phone" id="phone" type="number" placeholder="Name" {...register("phone")} />
                                                    </div>
                                                    <div className="tp-contact-input-title">
                                                        <label htmlFor="phone">Your Phone no</label>
                                                    </div>
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.phone?.message}
                                                    </div>
                                                </div>
                                                <div className="tp-contact-input-box">
                                                    <div className="tp-contact-input">
                                                        <input name="subject" id="subject" type="text" placeholder="Write your subject" {...register("subject")} />
                                                    </div>
                                                    <div className="tp-contact-input-title">
                                                        <label htmlFor="subject">Subject</label>
                                                    </div>
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.subject?.message}
                                                    </div>
                                                </div>
                                                <div className="tp-contact-input-box">
                                                    <div className="tp-contact-input">
                                                        <textarea id="message" name="message" placeholder="Write your message here..." {...register("messages")}></textarea>
                                                    </div>
                                                    <div className="tp-contact-input-title">
                                                        <label htmlFor="message">Your Message</label>
                                                    </div>
                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                        {errors.messages?.message}
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            {/* <div className="tp-contact-btn tp-btn-2"> */}
                                            <button className="tp-contact-btn tp-btn-2" type="submit">
                                                Send Message
                                            </button>
                                            {/* </div> */}
                                        </form>
                                        <p className="ajax-response"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4">
                                <div className="tp-contact-info-wrapper">
                                    <div className="tp-contact-info-item">
                                        <div className="tp-contact-info-icon">
                                            <span>
                                                <img src="assets/img/contact/contact-icon-1.png" alt="" />
                                            </span>
                                        </div>
                                        <div className="tp-contact-info-content">
                                            <p data-info="mail">
                                                <a href="mailto:contact@shofy.com">info@sunmarketing.org</a>
                                            </p>
                                            <p data-info="phone">
                                                (+91) 9173060881
                                            </p>
                                        </div>
                                    </div>
                                    <div className="tp-contact-info-item">
                                        <div className="tp-contact-info-icon">
                                            <span>
                                                <img src="assets/img/contact/contact-icon-2.png" alt="" />
                                            </span>
                                        </div>
                                        <div className="tp-contact-info-content">
                                            <p>
                                                <a href="https://www.google.com/maps/place/23%C2%B001'48.8%22N+72%C2%B035'22.6%22E/@23.030212,72.587421,16z/data=!4m4!3m3!8m2!3d23.0302222!4d72.5896111?hl=en&entry=ttu" target="_blank" rel="noreferrer">
                                                    A-06 Parkash Cinema, Govind Lila Complex Patni Sheri, Gheekata, Ahmedabad - 380001, Gujarat, India
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="tp-contact-info-item">
                                        <div className="tp-contact-info-icon">
                                            <span>
                                                <img src="assets/img/contact/contact-icon-3.png" alt="" />
                                            </span>
                                        </div>
                                        <div className="tp-contact-info-content">
                                            <div className="tp-contact-social-wrapper mt-5">
                                                <h4 className="tp-contact-social-title">Find on social media</h4>
                                                <div className="tp-contact-social-icon">
                                                    <a href={socialLinks?.facebook && socialLinks.facebook} target='_blank' rel="noreferrer"><i className="fa-brands fa-facebook-f"></i></a>
                                                    <a href={socialLinks?.twitter && socialLinks.twitter} target='_blank' rel="noreferrer"><i className="fa-brands fa-twitter"></i></a>
                                                    <a href={socialLinks?.linkedin && socialLinks.linkedin} target='_blank' rel="noreferrer"><i className="fa-brands fa-linkedin-in"></i></a>
                                                    <a href={socialLinks?.instagram && socialLinks.instagram} target='_blank' rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tp-contact-area pb-100">
                <div className="container">
                    <div className="tp-contact-inner">
                        <div className="row">
                            <h3 className="tp-contact-title">Frequently Asked Questions (FAQs) </h3>
                            {faqsList &&
                                faqsList.map((item) => {
                                    return (
                                        <Accordion key={item.id}>
                                            <AccordionSummary expandIcon={<ExpandMore />} aria-controls={item.id} id={item.id}>
                                                <Typography>{item.question}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>{item.answer}</Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- map area start --> */}
            <section class="tp-map-area pb-120">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="tp-map-wrapper">
                                <div class="tp-map-hotspot">
                                    <span class="tp-hotspot tp-pulse-border">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="6" cy="6" r="6" fill="#821F40" />
                                        </svg>
                                    </span>
                                </div>
                                <div class="tp-map-iframe">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3671.824587555448!2d72.58742131496787!3d23.030211984949247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjPCsDAxJzQ4LjgiTiA3MsKwMzUnMjIuNiJF!5e0!3m2!1sen!2sin!4v1688832606372!5m2!1sen!2sin" title="map  " width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- map area end --> */}
        </>
    );
};

export default Contact;
