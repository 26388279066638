import { axiosClient } from "./apiClient";

export function webContactUs(data) {
    return axiosClient.post("webcontactus", data);
}
export function getFaqs(data) {
    return axiosClient.post("faqslist", data);
}
export function webcategorylist(data) {
    return axiosClient.post("webcategorylist", data)
}
export function webslideshow(data) {
    return axiosClient.post("webslideshow", data)
}
export function websociallink(data) {
    return axiosClient.post("websociallink", data)
}
export function getaboutus(data) {
    return axiosClient.post("getaboutus", data)
}
export function webvideolist(data) {
    return axiosClient.post("webvideolist", data)
}
export function websearchproduct(data) {
    return axiosClient.post('websearchproduct', data)
}
export function websendinquiry(data) {
    return axiosClient.post('websendinquiry', data)
}
export function webaddrating(data) {
    return axiosClient.post('webaddrating', data)
}
export function webgetproductrating(data) {
    return axiosClient.post('webgetproductrating', data)
}
export function webproductlist(data) {
    return axiosClient.post('webproductlist', data)
}
export function webcategoryproduct(data) {
    return axiosClient.post('webcategoryproduct', data)
}
export function webhomeproduct(data) {
    return axiosClient.post('webhomeproduct', data)
}
export function webaddsubcribe(data) {
    return axiosClient.post('webaddsubcribe', data);
}
export function webhomediscount(data) {
    return axiosClient.post('webhomediscount', data);
}
