import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, Button } from "antd";
import { webaddrating, webgetproductrating, webproductlist, websendinquiry, websociallink } from "../../api/apiHandler";
import { Rate } from 'antd';
import Swal from 'sweetalert2';
import { isEmpty } from 'lodash';
import Spinner from '../../components/Spinner';
import capitalizedString from '../../util/capitalizedString';
import formateDate from '../../util/formateDate'

const RATING_NUM = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5
}

const ProductDetails = () => {
  const { prod_id } = useParams();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Email address is invalid").required("Email is required"),
    review: Yup.string().required("Review is required"),
  });

  const validationSchemaForInquiry = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Email address is invalid").required("Email is required"),
    phone: Yup.string()
      .required('Phone number is required')
      .matches(/^[1-9][0-9]{9}$/, 'Phone number must be exactly 10 digits')
      .length(10, 'Phone number must be exactly 10 digits'),
    messages: Yup.string().required("Message is required"),
  });


  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [rating, setRating] = useState(3);
  const [product, setProduct] = useState([])
  const [productInfo, setProductInfo] = useState({})
  const [productImage, setProductImage] = useState([]);
  const [productVideo, setProductVideo] = useState([]);
  const [productRatings, setProductRatings] = useState();
  const [starData, setStarData] = useState();
  const [mainImage, setMainImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen_Product, setIsModlaOpen_Product] = useState(false);
  const [video, setvideo] = useState(null);

  const [socialLinks, setSocialLinks] = useState()

  // product rating form
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  // product inquiry form
  const formOptions1 = { resolver: yupResolver(validationSchemaForInquiry) };
  const { register: registerInquiry, handleSubmit: handleSubmitInquiry, reset: resetInquiry, formState: formStateInquiry } = useForm(formOptions1);
  const { errors: errorsInquiry } = formStateInquiry

  useEffect(() => {
    websociallink({}).then((response) => {
      if (response.code == 1) {
        setSocialLinks(response.data[0]);
      } else {
        //   setSocialLinks([]);
      }
    });
    webgetproductrating({ product_id: +prod_id }).then((response) => {
      if (response.code == 1) {
        setProductRatings(response.data.rating_data)
        setStarData(response.data.star_data)
      } else {
        //   setSocialLinks([]);
      }
    });
  }, []);

  useEffect(() => {
    setIsLoadingProduct(true);
    webproductlist({ id: prod_id }).then((response) => {
      if (response.code == 1) {
        setIsLoadingProduct(false);
        setProduct(response.data[0])
        setProductImage(response.data[0].image)
        setMainImage(response.data[0].image[0].image)
        setProductVideo(response.data[0].video)
        setProductInfo(response.data[0].info[0])
      } else {
        setIsLoadingProduct(false);
        setProduct([])
      }
    });
  }, [prod_id])

  const onSubmitRatingForm = (data) => {
    const params = { ...data, rating, product_id: prod_id }
    webaddrating(params).then((response) => {
      if (response.code == 1) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        reset();
        setRating(3)
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const onSubmitInquiryForm = (data) => {
    const params = { ...data, product_id: prod_id }
    websendinquiry(params).then((response) => {
      if (response.code == 1) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        resetInquiry();
        setIsModlaOpen_Product(false);
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showProductModal = () => {
    setIsModlaOpen_Product(true);
  };

  const handleCancelProduct = () => {
    setIsModlaOpen_Product(false);
  };
  const buttonProps = {
    style: {
      background: "#06065f",
      borderColor: "white",
      color: "white",
    },
  };

  function validateEmbeddedYouTubeUrl(url) {
    const embeddedYouTubePattern = /^(https?\:\/\/)?(www\.)?youtube\.com\/embed\/.+$/;
    return embeddedYouTubePattern.test(url);
  }

  function handleProductVideo() {
    const isValidEmbeddedYouTubeUrl = validateEmbeddedYouTubeUrl(productVideo[0].video);
    showModal();
    if (isValidEmbeddedYouTubeUrl) {
      setvideo(productVideo[0].video)
    } else {
      setvideo("")
    }
  }

  return (
    <>
      {isModalOpen && (
        <Modal
          title={product.name}
          open={isModalOpen}
          className="video_modal"
          width="700px"
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" onClick={handleCancel} {...buttonProps}>
              Ok
            </Button>,
          ]}
        >
          <div>
            {video ? <iframe
              width="100%"
              height="360"
              src={video}
              title="YouTube Video"
              frameBorder="0"
              allowFullScreen
            /> :
              <div>
                <center style={{ padding: "20px" }}><h4>Product video not found.</h4></center>
              </div>
            }
          </div>
        </Modal>
      )}

      <main>
        {isModalOpen_Product && (
          <Modal
            title={product.name}
            open={isModalOpen_Product}
            onCancel={handleCancelProduct}
            width="800px"
            footer={[]}
          >
            <div className="modal_product">
              <div className="modal_product_sub">
                <div className="">
                  <img
                    src={`${process.env.REACT_APP_IMAGES}${productImage[0].image}`}
                    width="90%"
                    alt=""
                  />
                  <p>Rs {product.price} / Piece</p>
                </div>

                <div className="modal_product_details">
                  <span style={{ fontSize: "10px" }}>Sold by - Sun Maketing</span>
                  <br />
                  <span> Brand - {product.brand}</span>
                  <br />
                  <span> Voltage - {productInfo.voltage}</span>
                  <br />
                  <span> Power - {productInfo.power}</span>
                  <br />
                  <span> One Step Buttonhole - {productInfo.one_step_buttonhole}</span>
                </div>
              </div>
              <div className="modal_product_sub_form">
                <p>Connect with "Sun Marketing"</p>
                <form onSubmit={handleSubmitInquiry(onSubmitInquiryForm)}>
                  <div className="tp-product-details-review-input-wrapper">
                    <div className="tp-product-details-review-input-box">
                      <div className="tp-product-details-review-input">
                        <input
                          name="nameInquiry"
                          id="nameInquiry"
                          type="text"
                          placeholder="Name"
                          {...registerInquiry("name")}
                        />
                      </div>
                      <div className="tp-product-details-review-input-title">
                        <label htmlFor="nameInquiry">Your Name</label>
                      </div>
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errorsInquiry.name?.message}
                      </div>
                    </div>
                    <div className="tp-product-details-review-input-box">
                      <div className="tp-product-details-review-input">
                        <input
                          name="emailInquiry"
                          id="emailInquiry"
                          type="text"
                          placeholder="Name"
                          {...registerInquiry("email")}
                        />
                      </div>
                      <div className="tp-product-details-review-input-title">
                        <label htmlFor="emailInquiry">Your Email</label>
                      </div>
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errorsInquiry.email?.message}
                      </div>
                    </div>
                    <div className="tp-product-details-review-input-box">
                      <div className="tp-product-details-review-input">
                        <input
                          name="phoneInquiry"
                          id="phoneInquiry"
                          type="number"
                          placeholder="Phone"
                          {...registerInquiry("phone")}
                        />
                      </div>
                      <div className="tp-product-details-review-input-title">
                        <label htmlFor="phoneInquiry">Your Phone no</label>
                      </div>
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errorsInquiry.phone?.message}
                      </div>
                    </div>
                    <div className="tp-product-details-review-input-box">
                      <div className="tp-product-details-review-input">
                        <textarea
                          id="msgInquiry"
                          name="msgInquiry"
                          placeholder="Write your review here..."
                          {...registerInquiry("messages")}
                        ></textarea>
                      </div>
                      <div className="tp-product-details-review-input-title">
                        <label htmlFor="msgInquiry">Your Message</label>
                      </div>
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errorsInquiry.messages?.message}
                      </div>
                    </div>
                  </div>
                  <div className="tp-product-details-review-btn-wrapper">
                    <button className="tp-product-details-review-btn" style={{ borderRadius: '5px' }}>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
        )}
        {isLoadingProduct && <Spinner />}
        {(!isLoadingProduct && isEmpty(product)) && <section className="tp-error-area pt-110 pb-110">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8 col-md-10">
                <div className="tp-error-content text-center">
                  <h3 className="tp-error-title">Oops! Product not found</h3>
                  <p>Whoops, this is embarassing. Looks like the page you were looking for wasn't found.</p>
                  <Link to="/" className="tp-error-btn">Back to Home</Link>
                </div>
              </div>
            </div>
          </div>
        </section>}
        {!isEmpty(product) && <> {/* <!-- breadcrumb area start --> */}
          <section className="breadcrumb__area breadcrumb__style-2 include-bg pt-50 pb-20">
            <div className="container">
              <div className="row">
                <div className="col-xxl-12">
                  <div className="breadcrumb__content p-relative z-index-1">
                    <div className="breadcrumb__list has-icon">
                      <span className="breadcrumb-icon">
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.42393 16H15.5759C15.6884 16 15.7962 15.9584 15.8758 15.8844C15.9553 15.8104 16 15.71 16 15.6054V6.29143C16 6.22989 15.9846 6.1692 15.9549 6.11422C15.9252 6.05923 15.8821 6.01147 15.829 5.97475L8.75305 1.07803C8.67992 1.02736 8.59118 1 8.5 1C8.40882 1 8.32008 1.02736 8.24695 1.07803L1.17098 5.97587C1.11791 6.01259 1.0748 6.06035 1.04511 6.11534C1.01543 6.17033 0.999976 6.23101 1 6.29255V15.6063C1.00027 15.7108 1.04504 15.8109 1.12451 15.8847C1.20398 15.9585 1.31165 16 1.42393 16ZM10.1464 15.2107H6.85241V10.6202H10.1464V15.2107ZM1.84866 6.48977L8.4999 1.88561L15.1517 6.48977V15.2107H10.9946V10.2256C10.9946 10.1209 10.95 10.0206 10.8704 9.94654C10.7909 9.87254 10.683 9.83096 10.5705 9.83096H6.42848C6.316 9.83096 6.20812 9.87254 6.12858 9.94654C6.04904 10.0206 6.00435 10.1209 6.00435 10.2256V15.2107H1.84806L1.84866 6.48977Z"
                            fill="#55585B"
                            stroke="#55585B"
                            strokeWidth="0.5"
                          />
                        </svg>
                      </span>
                      <span>
                        <NavLink to="/">Home</NavLink>
                      </span>
                      <span>
                        Products
                      </span>
                      <span>
                        <NavLink to={`/category/${product.category_id}`}>{capitalizedString(product.category_name)}</NavLink>
                      </span>
                      <span>{capitalizedString(product.name)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- breadcrumb area end --> */}

          {/* <!-- product details area start --> */}
          <section className="tp-product-details-area">
            <div className="tp-product-details-top pb-115">
              <div className="container">
                <div className="row">
                  <div className="col-xl-7 col-lg-6">
                    <div className="tp-product-details-thumb-wrapper tp-tab d-sm-flex">
                      <nav>
                        <div
                          className="nav nav-tabs flex-sm-row nav-tabs1"
                          id="productDetailsNavThumb"
                          role="tablist"
                          style={{ overflow: "auto" }}
                        >
                          {productImage.map((val, index) => <button
                            key={val.id}
                            className={`nav-link ${val == mainImage && 'active'}`}
                            id={`nav-${index + 1}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#nav-${index + 1}`}

                            type="button"
                            role="tab"
                            aria-controls="nav-1"
                            aria-selected="true"
                            onClick={() => setMainImage(val.image)}
                          >
                            <img
                              src={`${process.env.REACT_APP_IMAGES}${val.image}`}
                              alt=""
                            />
                          </button>)}
                        </div>
                      </nav>
                      <div
                        className="tab-content m-img"
                        id="productDetailsNavContent"
                      >
                        <div
                          className="tab-pane fade show active"
                          // id="nav-1"
                          role="tabpanel"
                          aria-labelledby="nav-1-tab"
                          tabIndex="0"
                        >
                          <div className="tp-product-details-nav-main-thumb">
                            <img
                              src={`${process.env.REACT_APP_IMAGES}${mainImage}`}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- col end --> */}
                  <div className="col-xl-5 col-lg-6">
                    <div className="tp-product-details-wrapper">
                      <div className="tp-product-details-category">
                        <span>{product.category_name}</span>
                      </div>
                      <h3 className="tp-product-details-title">
                        {product.name}
                      </h3>

                      {/* <!-- inventory details --> */}
                      <div className="tp-product-details-inventory d-flex align-items-center mb-10">
                        <div className="tp-product-details-stock mb-10">
                          <span>In Stock</span>
                        </div>
                        <div className="tp-product-details-rating-wrapper d-flex align-items-center mb-10">
                          <Rate value={product.avg_rating} defaultValue={0} disabled />
                          <div className="tp-product-details-reviews" style={{ marginTop: "5px", marginLeft: "10px" }}>
                            <span>({product.total_review} Reviews)</span>
                          </div>
                        </div>
                      </div>
                      <p>
                        {product.description}
                      </p>

                      {/* <!-- price --> */}
                      <div className="tp-product-details-price-wrapper mb-20">
                        <span className="tp-product-details-price old-price">
                          Rs.{product.price}
                        </span>
                        <span className="tp-product-details-price new-price">
                          &nbsp;Rs.{product.offer_price}
                        </span>
                      </div>

                      {/* <!-- actions --> */}
                      <div className="tp-product-details-action-wrapper">
                        <div className="tp-product-details-action-item-wrapper d-flex align-items-center">
                          <div className="tp-product-details-add-to-cart mb-15 w-100">
                            {!isEmpty(productVideo) && <button
                              className="tp-product-details-add-to-cart-btn w-100"
                              onClick={handleProductVideo}
                            >
                              Watch Video
                            </button>}
                          </div>
                        </div>
                        <button
                          className="tp-product-details-buy-now-btn w-100"
                          onClick={showProductModal}
                        >
                          Yes! I am Interested
                        </button>
                      </div>
                      <div className="tp-product-details-query">
                        <div className="tp-product-details-query-item d-flex align-items-center">
                          <span>SKU: </span>
                          <p>{product.sku}</p>
                        </div>
                        <div className="tp-product-details-query-item d-flex align-items-center">
                          <span>Category: </span>
                          <p>{product.category_name}</p>
                        </div>
                        <div className="tp-product-details-query-item d-flex align-items-center">
                          <span>Brand: </span>
                          <p>{product.brand}</p>
                        </div>
                      </div>
                      <div className="tp-product-details-social">
                        <span>Share: </span>
                        <a href={socialLinks?.facebook && socialLinks.facebook} target='_blank' rel="noreferrer"><i className="fa-brands fa-facebook-f"></i></a>
                        <a href={socialLinks?.twitter && socialLinks.twitter} target='_blank' rel="noreferrer"><i className="fa-brands fa-twitter"></i></a>
                        <a href={socialLinks?.linkedin && socialLinks.linkedin} target='_blank' rel="noreferrer"><i className="fa-brands fa-youtube"></i></a>
                        <a href={socialLinks?.instagram && socialLinks.instagram} target='_blank' rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                      </div>
                      {/* <div className="tp-product-details-msg mb-15">
                      <ul>
                        <li>30 days easy returns</li>
                        <li>Order yours before 2.30pm for same day dispatch</li>
                      </ul>
                    </div>
                    <div className="tp-product-details-payment d-flex align-items-center flex-wrap justify-content-between">
                      <p>
                        Guaranteed safe <br /> & secure checkout
                      </p>
                      <img
                        src="assets/img/product/icons/payment-option.png"
                        alt=""
                      />
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tp-product-details-bottom pb-140">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="tp-product-details-tab-nav tp-tab">
                      <nav>
                        <div
                          className="nav nav-tabs justify-content-center p-relative tp-product-tab"
                          id="navPresentationTab"
                          role="tablist"
                        >
                          <button
                            className="nav-link"
                            id="nav-description-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-description"
                            type="button"
                            role="tab"
                            aria-controls="nav-description"
                            aria-selected="true"
                          >
                            Description
                          </button>
                          <button
                            className="nav-link active"
                            id="nav-addInfo-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-addInfo"
                            type="button"
                            role="tab"
                            aria-controls="nav-addInfo"
                            aria-selected="false"
                          >
                            Additional information
                          </button>
                          {/* <button
                          className="nav-link"
                          id="nav-review-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-review"
                          type="button"
                          role="tab"
                          aria-controls="nav-review"
                          aria-selected="false"
                        >
                          Reviews (2)
                        </button> */}

                          <span
                            id="productTabMarker"
                            className="tp-product-details-tab-line"
                          ></span>
                        </div>
                      </nav>
                      <div className="tab-content" id="navPresentationTabContent">
                        <div
                          className="tab-pane fade"
                          id="nav-description"
                          role="tabpanel"
                          aria-labelledby="nav-description-tab"
                          tabIndex="0"
                        >
                          <div className="tp-product-details-desc-wrapper pt-80">
                            <div className="row justify-content-center">
                              <div className="col-xl-10">
                                <div className="tp-product-details-desc-item pb-105">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="tp-product-details-desc-content pt-25">
                                        <span>{product.category_name}</span>
                                        <h3 className="tp-product-details-desc-title">
                                          {product.name}
                                        </h3>
                                        <p>
                                          {product.description}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade show active"
                          id="nav-addInfo"
                          role="tabpanel"
                          aria-labelledby="nav-addInfo-tab"
                          tabIndex="0"
                        >
                          <div className="tp-product-details-additional-info ">
                            <div className="row justify-content-center">
                              <div className="col-xl-10">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>Brand</td>
                                      <td>{product.brand}</td>
                                    </tr>
                                    {productInfo.voltage && <tr>
                                      <td>Voltage</td>
                                      <td>{productInfo.voltage}</td>
                                    </tr>}
                                    {productInfo.power && <tr>
                                      <td>Power</td>
                                      <td>{productInfo.power}</td>
                                    </tr>}
                                    {productInfo.one_step_buttonhole && <tr>
                                      <td>One Step Buttonhole</td>
                                      <td>{productInfo.one_step_buttonhole}</td>
                                    </tr>}
                                    {productInfo.needle && <tr>
                                      <td>Needle Recommendation</td>
                                      <td>{productInfo.needle}</td>
                                    </tr>}
                                    {productInfo.machine_size && <tr>
                                      <td>Machine Size</td>
                                      <td>{productInfo.machine_size}</td>
                                    </tr>}
                                    {productInfo.box_size && <tr>
                                      <td>Box Size</td>
                                      <td>{productInfo.box_size}</td>
                                    </tr>}
                                    {productInfo.current && <tr>
                                      <td>Current</td>
                                      <td>{productInfo.current}</td>
                                    </tr>}
                                    {productInfo.machine_weight && <tr>
                                      <td>Machine Weight</td>
                                      <td>{productInfo.machine_weight}</td>
                                    </tr>}
                                    {productInfo.box_weight && <tr>
                                      <td>Box Weight</td>
                                      <td>{productInfo.box_weight}</td>
                                    </tr>}

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <nav>
                        <div
                          className="nav nav-tabs justify-content-center p-relative tp-product-tab"
                          id="navPresentationTab"
                          role="tablist"
                          style={{ marginTop: "50px" }}
                        >
                          <h3> Reviews </h3>
                        </div>
                      </nav>
                      <div
                        className="tab-pane "
                        id="nav-reviewq"
                        role="tabpanel"
                        aria-labelledby="nav-review-tab"
                        tabIndex="0"
                      >
                        <div className="tp-product-details-review-wrapper pt-60">
                          <div className={`row ${product.total_review <= 0 && "justify-content-center"}`}>
                            {product.total_review > 0 && <div className="col-lg-6">
                              <div className="tp-product-details-review-statics">
                                <div className="tp-product-details-review-number d-inline-block mb-50">
                                  <h3 className="tp-product-details-review-number-title">
                                    Customer reviews
                                  </h3>
                                  <div className="tp-product-details-review-summery d-flex align-items-center">
                                    <div className="tp-product-details-review-summery-value">
                                      <span>{product.avg_rating.toFixed(1)}</span>
                                    </div>
                                    <Rate value={product.avg_rating} disabled />
                                    <div className="tp-product-details-review-summery-rating d-flex align-items-center">
                                      <p>({product.total_review} Reviews)</p>
                                    </div>
                                  </div>
                                  <div className="tp-product-details-review-rating-list">
                                    {Object.entries(starData).map(([key, value], index) => (
                                      <div className="tp-product-details-review-rating-item d-flex align-items-center" key={key}>
                                        <span>{RATING_NUM[key]} Star</span>
                                        <div className="tp-product-details-review-rating-bar">
                                          <span
                                            className="tp-product-details-review-rating-bar-inner"
                                            data-width={`${value}%`}
                                            style={{ width: `${value}%` }}
                                          ></span>
                                        </div>
                                        <div className="tp-product-details-review-rating-percent">
                                          <span>{value}%</span>
                                        </div>
                                      </div>
                                    ))}

                                  </div>
                                </div>

                                <div className="tp-product-details-review-list pr-110">
                                  <h3 className="tp-product-details-review-title">
                                    Rating & Review
                                  </h3>
                                  {!isEmpty(productRatings) && productRatings.map((val) => {
                                    return <div className="tp-product-details-review-avater d-flex align-items-start" key={val.id}>
                                      <div className="tp-product-details-review-avater-content">
                                        <Rate value={val.rating} disabled />
                                        <div className="tp-product-details-review-avater-rating d-flex align-items-center">
                                        </div>
                                        <h3 className="tp-product-details-review-avater-title">
                                          {val.name}
                                        </h3>
                                        <span className="tp-product-details-review-avater-meta">
                                          {formateDate(val.created_at)}
                                        </span>
                                        <div className="tp-product-details-review-avater-comment">
                                          <p>
                                            {val.review}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  })}
                                </div>
                              </div>
                            </div>}
                            {/* <!-- end col --> */}
                            <div className={`${product.total_review > 0 ? "col-lg-6" : "col-lg-8"}`} >
                              <div className="tp-product-details-review-form">
                                <h3 className="tp-product-details-review-form-title">
                                  Review this product
                                </h3>
                                <p>
                                  Your email address will not be published.
                                  Required fields are marked *
                                </p>
                                <form onSubmit={handleSubmit(onSubmitRatingForm)}>
                                  <div className="tp-product-details-review-form-rating d-flex align-items-center">
                                    <p>Your Rating :</p>
                                    <Rate onChange={setRating} value={rating} defaultValue={3} />
                                  </div>
                                  <div className="tp-product-details-review-input-wrapper">
                                    <div className="tp-product-details-review-input-box">
                                      <div className="tp-product-details-review-input">
                                        <input
                                          name="name"
                                          id="name"
                                          type="text"
                                          placeholder="Name"
                                          {...register("name")}
                                        />
                                      </div>
                                      <div className="tp-product-details-review-input-title">
                                        <label htmlFor="name">Your Name</label>
                                      </div>
                                      <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                      >
                                        {errors.name?.message}
                                      </div>
                                    </div>
                                    <div className="tp-product-details-review-input-box">
                                      <div className="tp-product-details-review-input">
                                        <input
                                          name="email"
                                          id="email"
                                          type="text"
                                          placeholder="Email"
                                          {...register("email")}
                                        />
                                      </div>
                                      <div className="tp-product-details-review-input-title">
                                        <label htmlFor="email">Your Email</label>
                                      </div>
                                      <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                      >
                                        {errors.email?.message}
                                      </div>
                                    </div>
                                    <div className="tp-product-details-review-input-box">
                                      <div className="tp-product-details-review-input">
                                        <textarea
                                          id="msg"
                                          name="msg"
                                          placeholder="Write your review here..."
                                          {...register("review")}
                                        ></textarea>
                                      </div>
                                      <div className="tp-product-details-review-input-title">
                                        <label htmlFor="msg">Your Review</label>
                                      </div>
                                      <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                      >
                                        {errors.review?.message}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="tp-product-details-review-btn-wrapper">
                                    <button className="tp-product-details-review-btn">
                                      Submit
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- product details area end --> */}</>}

      </main>
    </>
  );
};

export default ProductDetails;
