import axios from "axios";
import CryptoJS from "crypto-js";
// import { logOutRedirectCall } from "../pages/common/RedirectPathMange";

var key = process.env.REACT_APP_KEY;
var iv = process.env.REACT_APP_IV;

const showMessage = (msg) => {
  // console.log(msg);
};

const axiosClient = axios.create({
  baseURL: "https://apis.sunmarketing.org/api/v1/admin/",
  headers: {
    "api-key": process.env.REACT_APP_API_KEY,
    "accept-language": "en",
    "Content-Type": "text/plain",
  },
});

// Body Encryption Request
axiosClient.interceptors.request.use(function (request) {
  request.data = bodyEncryption(request.data, true);

  // if (localStorage.getItem("Atoken") !== undefined || localStorage.getItem("Atoken") !== null) {
  //   request.headers["token"] = localStorage.getItem("Atoken");
  // }

  return request;
});

axiosClient.interceptors.response.use(function (response) {
  response = bodyDecryption(response.data);

  if (response.code == 0) {
    showMessage(response?.data?.message)
  }
  return response;
},
  function (error) {
    let res = error.response;
    if (res.status == 401) {
      // logOutRedirectCall()
    }
    console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);

function bodyEncryption(request, isStringify) {
  var request = (isStringify) ? JSON.stringify(request) : request;
  var encrypted = CryptoJS.AES.encrypt(request, key, { iv: iv });
  return encrypted.toString();
}

function bodyDecryption(request) {
  var decrypted = CryptoJS.AES.decrypt(request.toString(), key, { iv: iv });
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
}

export { axiosClient };