import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Rate } from "antd";
import { ProductData } from "../../data/ProductData";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Slider from "react-slick";
import { useEffect } from "react";
import { webaddsubcribe, webcategorylist, webhomediscount, webhomeproduct, websendinquiry, webslideshow, webvideolist } from "../../api/apiHandler";
import Swal from 'sweetalert2';
import { isEmpty } from 'lodash';
import Spinner from '../../components/Spinner';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState(ProductData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen_Product, setIsModlaOpen_Product] = useState(false);
  const [tab, setTab] = useState("new");
  const [slideShow, setSlideShow] = useState(null);
  const [productVideo, setProductVideo] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [video, setvideo] = useState(null);
  const [modalData, setModalData] = useState({});
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [email, setEmail] = useState("");
  const [discountBannerList, setDiscountBannerList] = useState([]);

  const validationSchemaForInquiry = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Email address is invalid").required("Email is required"),
    phone: Yup.string()
      .required('Phone number is required')
      .matches(/^[1-9][0-9]{9}$/, 'Phone number must be exactly 10 digits')
      .length(10, 'Phone number must be exactly 10 digits'),
    messages: Yup.string().required("Message is required"),
  });

  // product inquiry form
  const formOptions1 = { resolver: yupResolver(validationSchemaForInquiry) };
  const { register: registerInquiry, handleSubmit: handleSubmitInquiry, reset: resetInquiry, formState: formStateInquiry } = useForm(formOptions1);
  const { errors: errorsInquiry } = formStateInquiry

  useEffect(() => {
    webslideshow({ id: 0 }).then((response) => {
      if (response.code == 1) {
        setSlideShow(response.data);
      } else {
        setSlideShow([]);
      }
    });
    webvideolist({ id: 0 }).then((response) => {
      if (response.code == 1) {
        setProductVideo(response.data);
      } else {
        setProductVideo([]);
      }
    });
    webcategorylist({ id: 0 }).then((response) => {
      if (response.code == 1) {
        setCategoryList(response.data)
      } else {
        setCategoryList([])
      }
    })
    webhomediscount({ id: 0 }).then((response) => {
      if (response.code == 1) {
        setDiscountBannerList(response.data)
      } else {
        setDiscountBannerList([]);
      }
    })
  }, []);

  useEffect(() => {
    setIsLoadingProduct(true)
    webhomeproduct({ value: tab }).then((response) => {
      if (response.code == 1) {
        setSortedData(response.data);
        setIsLoadingProduct(false)
      } else {
        setIsLoadingProduct(false)
        setSortedData([]);
      }
    });
    return () => {
      setIsLoadingProduct(false)
      setSortedData([]);
    }
  }, [tab])

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showProductModal = () => {
    setIsModlaOpen_Product(true);
  };

  const handleCancelProduct = () => {
    setIsModlaOpen_Product(false);
  };
  const buttonProps = {
    style: {
      background: "#06065f",
      borderColor: "white",
      color: "white",
    },
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    slickNext: false,
    arrows: false
  };

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    slickNext: false,
    nextArrow: (
      <div style={{ marginBottom: "20px" }}>
        <div className="next-slick-arrow">
          <svg xmlns="http://www.w3.org/2000/svg" stroke="black" height="24" viewBox="0 -960 960 960" width="24"><path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z" /></svg>
        </div>
      </div>
    ),

    prevArrow: (
      <div>
        <div className="next-slick-arrow rotate-180">
          <svg xmlns="http://www.w3.org/2000/svg" stroke="black" height="24" viewBox="0 -960 960 960" width="24"><path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z" /></svg>
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  const onSubmitInquiryForm = (data) => {
    const params = { ...data, product_id: modalData.id }
    websendinquiry(params).then((response) => {
      if (response.code == 1) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
        resetInquiry();
        setIsModlaOpen_Product(false);
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  }

  const handleNewsSubmit = (e) => {
    e.preventDefault();
    if (email) {
      webaddsubcribe({ email }).then(response => {
        if (response.code == 1) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
          setEmail("");
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
    }
  }

  return (
    <>
      {isModalOpen && (
        <Modal
          title="Singer C440 Legacy Sewing Machine"
          open={isModalOpen}
          className="video_modal"
          width="700px"
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" onClick={handleCancel} {...buttonProps}>
              Ok
            </Button>,
          ]}
        >
          <div>
            <iframe
              width="100%"
              height="360"
              src={video}
              title="YouTube Video"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </Modal>
      )}

      {isModalOpen_Product && (
        <Modal
          title={modalData.name}
          open={isModalOpen_Product}
          onCancel={handleCancelProduct}
          width="800px"
          footer={[]}
        >
          <div className="modal_product">
            <div className="modal_product_sub">
              <div className="">
                <img
                  src={modalData.image}
                  width="90%"
                  alt=""
                />
                {/* <p>Rs 70,000 / Piece</p> */}
              </div>

              <div className="modal_product_details">
                <span>Sold by - Sun Maketing</span>
                <br />
                {/* <span> Brand - Singer</span>
                <br />
                <span> Voltage - 110/220v</span>
                <br />
                <span> Power - 55W</span>
                <br />
                <span> One Step Buttonhole - 13</span>
                <br />
                <span>Bobbin Class - 15 Transparent</span> */}
              </div>
            </div>
            <div className="modal_product_sub_form">
              <p>Connect with "Sun Marketing"</p>
              <form onSubmit={handleSubmitInquiry(onSubmitInquiryForm)}>
                <div className="tp-product-details-review-input-wrapper">
                  <div className="tp-product-details-review-input-box">
                    <div className="tp-product-details-review-input">
                      <input
                        name="nameInquiry"
                        id="nameInquiry"
                        type="text"
                        placeholder="Name"
                        {...registerInquiry("name")}
                      />
                    </div>
                    <div className="tp-product-details-review-input-title">
                      <label htmlFor="nameInquiry">Your Name</label>
                    </div>
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errorsInquiry.name?.message}
                    </div>
                  </div>
                  <div className="tp-product-details-review-input-box">
                    <div className="tp-product-details-review-input">
                      <input
                        name="emailInquiry"
                        id="emailInquiry"
                        type="text"
                        placeholder="Name"
                        {...registerInquiry("email")}
                      />
                    </div>
                    <div className="tp-product-details-review-input-title">
                      <label htmlFor="emailInquiry">Your Email</label>
                    </div>
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errorsInquiry.email?.message}
                    </div>
                  </div>
                  <div className="tp-product-details-review-input-box">
                    <div className="tp-product-details-review-input">
                      <input
                        name="phoneInquiry"
                        id="phoneInquiry"
                        type="number"
                        placeholder="Phone"
                        {...registerInquiry("phone")}
                      />
                    </div>
                    <div className="tp-product-details-review-input-title">
                      <label htmlFor="phoneInquiry">Your Phone no</label>
                    </div>
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errorsInquiry.phone?.message}
                    </div>
                  </div>
                  <div className="tp-product-details-review-input-box">
                    <div className="tp-product-details-review-input">
                      <textarea
                        id="msgInquiry"
                        name="msgInquiry"
                        placeholder="Write your review here..."
                        {...registerInquiry("messages")}
                      ></textarea>
                    </div>
                    <div className="tp-product-details-review-input-title">
                      <label htmlFor="msgInquiry">Your Message</label>
                    </div>
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errorsInquiry.messages?.message}
                    </div>
                  </div>
                </div>
                <div className="tp-product-details-review-btn-wrapper">
                  <button className="tp-product-details-review-btn" style={{ borderRadius: '5px' }}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      )}

      <main>
        {/* <!-- slider area start --> */}
        <section className="tp-slider-area p-relative z-index-1">
          <div className="tp-slider-active tp-slider-variation swiper-container">
            <div className="swiper-wrapper">
              <div className="tp-slider-item tp-slider-height d-flex align-items-center swiper-slide green-dark-bg">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="tp-slider-content p-relative z-index-1">
                        <span>
                          Starting at <b>$274.00</b>
                        </span>
                        <h3 className="tp-slider-title">
                          Sun Marketing are leading Manufacturer and Wholesale
                          Trader
                        </h3>
                        <p>
                          Exclusive offer
                          <span>
                            &nbsp;-5%&nbsp;
                            <svg
                              width="94"
                              height="20"
                              viewBox="0 0 94 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M74.8576 4.63367L78.6048 5.11367C80.9097 5.35155 82.8309 5.75148 84.4483 5.97993L86.6581 6.31091L88.4262 6.63948C89.4684 6.81761 90.2699 6.9312 90.8805 6.99186C93.3213 7.24888 92.7011 6.63674 92.8183 6.12534C92.9355 5.61394 93.7175 5.37081 91.3267 4.45886C90.73 4.24001 89.9345 3.97481 88.8826 3.65818L87.1034 3.12577L84.8643 2.63282C83.236 2.28025 81.2402 1.82307 78.8684 1.52138L75.0177 0.981633C73.6188 0.823014 72.1417 0.730003 70.5389 0.582533C63.0297 0.0282543 55.4847 0.193022 48.0068 1.07459C39.9065 2.04304 31.9328 3.87384 24.2213 6.53586C18.0824 8.61764 12.1674 11.3089 6.56479 14.5692C4.88189 15.5255 3.25403 16.5756 1.68892 17.7145C0.568976 18.5077 -0.00964231 18.9932 0.0547097 19.0858C0.388606 19.6584 10.6194 13.1924 25.151 8.99361C32.789 6.72748 40.6283 5.20536 48.5593 4.44848C55.8569 3.76455 63.1992 3.69678 70.5082 4.24591L74.8223 4.62335"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          off this week
                        </p>

                        <div className="">
                          <NavLink
                            to="/products"
                            className="tp-btn tp-btn-2 tp-btn-white"
                          >
                            Shop Now&nbsp;
                            <svg
                              width="17"
                              height="14"
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16 6.99976L1 6.99976"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.9502 0.975414L16.0002 6.99941L9.9502 13.0244"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <Slider {...settings}>
                        {slideShow && slideShow.map(val =>
                          <div className="tp-slider-thumb text-end" key={val.id}>
                            <img src={`${process.env.REACT_APP_IMAGES}${val.image}`} height={350} width={400} alt="slider-img" />
                          </div>
                        )}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Category banner */}
        <section className="tp-product-category pt-50 pb-15">
          <div className="container mb-4">
            {/* <div className="row row-cols-2 row-cols-sm-2 row-cols-xl-4 row-cols-lg-4 row-cols-md-4 row-cols-sm-2"> */}
            <Slider {...settings1}>
              {categoryList && categoryList.map(val => <div className="col" key={val.id}>
                <div className="tp-product-category-item text-center mb-40" style={{ marginLeft: "20px" }}>
                  <div className="tp-product-category-thumb fix">
                    <NavLink to={`/category/${val.id}`}>
                      <img
                        src={`${process.env.REACT_APP_IMAGES}${val.image}`}
                        alt="product-category"
                        className="img-fluid"
                      />
                    </NavLink>
                  </div>
                  <div className="tp-product-category-content">
                    <h3 className="tp-product-category-title">
                      <NavLink to={`/category/${val.id}`}>{val.category_name}</NavLink>
                    </h3>
                    <p>{val.product_count} Product</p>
                  </div>
                </div>
              </div>)}
            </Slider>
          </div>
          {/* </div> */}
        </section>

        {/* Trending Products */}
        <section className="tp-product-area pb-55">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-xl-5 col-lg-6 col-md-5">
                <div className="tp-section-title-wrapper mb-40">
                  <h3 className="tp-section-title">
                    Trending Products
                    <svg
                      width="114"
                      height="35"
                      viewBox="0 0 114 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M112 23.275C1.84952 -10.6834 -7.36586 1.48086 7.50443 32.9053"
                        stroke="currentColor"
                        strokeWidth="4"
                        strokeMiterlimit="3.8637"
                        strokeLinecap="round"
                      ></path>
                    </svg>
                  </h3>
                </div>
              </div>
              <div className="col-xl-7 col-lg-6 col-md-7">
                <div className="tp-product-tab tp-product-tab-border mb-45 tp-tab d-flex justify-content-md-end">
                  <ul
                    className="nav nav-tabs justify-content-sm-end"
                    id="productTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="new-tab"
                        data-bs-toggle="tab"
                        // data-bs-target="/new-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="new-tab-pane"
                        aria-selected="true"
                        onClick={() => setTab("new")}
                      >
                        New
                        <span className="tp-product-tab-line">
                          <svg
                            width="52"
                            height="13"
                            viewBox="0 0 52 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 8.97127C11.6061 -5.48521 33 3.99996 51 11.4635"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeMiterlimit="3.8637"
                              strokeLinecap="round"
                            ></path>
                          </svg>
                        </span>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="featured-tab"
                        data-bs-toggle="tab"
                        // data-bs-target="/featured-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="featured-tab-pane"
                        aria-selected="false"
                        onClick={() => setTab("featured")}
                      >
                        Featured
                        <span className="tp-product-tab-line">
                          <svg
                            width="52"
                            height="13"
                            viewBox="0 0 52 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 8.97127C11.6061 -5.48521 33 3.99996 51 11.4635"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeMiterlimit="3.8637"
                              strokeLinecap="round"
                            ></path>
                          </svg>
                        </span>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="topsell-tab"
                        data-bs-toggle="tab"
                        // data-bs-target="/topsell-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="topsell-tab-pane"
                        aria-selected="false"
                        onClick={() => setTab("topSeller")}
                      >
                        Top Sellers
                        <span className="tp-product-tab-line">
                          <svg
                            width="52"
                            height="13"
                            viewBox="0 0 52 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 8.97127C11.6061 -5.48521 33 3.99996 51 11.4635"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeMiterlimit="3.8637"
                              strokeLinecap="round"
                            ></path>
                          </svg>
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="tp-product-tab-content">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="new-tab-pane"
                      role="tabpanel"
                      aria-labelledby="new-tab"
                      tabIndex="0"
                    >
                      <div className="row">
                        {isLoadingProduct && <Spinner />}
                        {!isEmpty(sortedData) && !isLoadingProduct ? sortedData.map((val) => {
                          return (
                            <div className="col-xl-3 col-lg-3 col-sm-6" key={val.id}>
                              <div className="tp-product-item p-relative transition-3 mb-25">
                                <div className="tp-product-thumb p-relative fix m-img">
                                  <NavLink to={`/category/${val.category_id}/${val.id}`}>
                                    <img
                                      src={`${process.env.REACT_APP_IMAGES}${val.image}`}
                                      alt="product-electronic"
                                    />
                                  </NavLink>
                                </div>
                                {/* <!-- product content --> */}
                                <div className="tp-product-content" style={{ height: "160px" }}>
                                  <div className="tp-product-category">
                                    <NavLink to={`/category/${val.category_id}/${val.id}`}>
                                      {val.brand}
                                    </NavLink>
                                  </div>
                                  <h3 className="tp-product-title">
                                    <NavLink to={`/category/${val.cat_id}/${val.prod_id}`}>
                                      {val.name}
                                    </NavLink>
                                  </h3>
                                  <div className="tp-product-rating d-flex align-items-center">
                                    <Rate value={val.avg_rating} defaultValue={0} disabled />
                                    <div className="tp-product-rating-text">
                                      <span>({val.total_review} Review)</span>
                                    </div>
                                  </div>
                                  <div className="tp-product-price-wrapper">
                                    <span className="tp-product-price old-price">
                                      Rs.{val.price}
                                    </span>
                                    <span className="tp-product-price new-price">
                                      &nbsp;Rs.{val.offer_price}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }) : <></>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Home banner */}
        {!isEmpty(discountBannerList) && <section className="tp-banner-area pb-70">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-7">
                <div className="tp-banner-item tp-banner-height p-relative mb-30 z-index-1 fix">
                  <div
                    className="tp-banner-thumb include-bg transition-3"
                    dataBackground={`${process.env.REACT_APP_IMAGES}${discountBannerList[0].poster}`}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_IMAGES}${discountBannerList[0].poster})`,
                      backgroundPositionX: "80%",
                    }}
                  ></div>
                  <div className="tp-banner-content">
                    <span>Sale {discountBannerList[0].discount}% off all store</span>
                    <h3 className="tp-banner-title">
                      {discountBannerList[0].heading}
                    </h3>
                    <div className="tp-banner-btn">

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5">
                <div className="tp-banner-item tp-banner-item-sm tp-banner-height p-relative mb-30 z-index-1 fix">
                  <div
                    className="tp-banner-thumb include-bg transition-3"
                    dataBackground={`${process.env.REACT_APP_IMAGES}${discountBannerList[1].poster}`}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_IMAGES}${discountBannerList[1].poster})`,
                    }}
                  ></div>
                  <div className="tp-banner-content">
                    <h3 className="tp-banner-title text-white" >
                      {discountBannerList[1].heading}
                    </h3>
                    <p className="text-white">Sale {discountBannerList[1].discount}% off</p>
                    <div className="tp-banner-btn">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        }

        {/* Video listing */}
        {!isEmpty(productVideo) && <section className="tp-shop-area pb-120">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="tp-shop-main-wrapper">
                  <h3>Product Videos</h3>
                  <div className="tp-shop-items-wrapper tp-shop-item-primary">
                    <div className="tab-content" id="productTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="grid-tab-pane"
                        role="tabpanel"
                        aria-labelledby="grid-tab"
                        tabIndex="0"
                      >
                        <div className="row">
                          {productVideo && productVideo.slice(0, 4).map(val => <div className="col-xl-3 col-lg-3 col-sm-6" key={val.id}>
                            <div className="tp-product-item p-relative transition-3 mb-25">
                              <div
                                className="tp-product-thumb p-relative fix m-img"
                                onClick={() => { setvideo(val.video); showModal(); }}
                              >
                                <img
                                  src={`${process.env.REACT_APP_IMAGES}${val.photo[0].image}`}
                                  alt="product-electronic"
                                />
                                <div className="vdo_icn video"></div>
                              </div>
                              {/* <!-- product content --> */}
                              <div className="tp-product-content" style={{ height: "130px" }}>
                                <div className="tp-product-category" style={{ height: "50px" }}>
                                  {val.name}
                                </div>
                                <h3 className="tp-product-title">
                                  {/* Singer C440 Legacy Sewing Machine */}
                                </h3>
                                <button
                                  className="tp-product-details-buy-now-btn"
                                  style={{ width: "100%", margin: "auto" }}
                                  onClick={() => { setModalData({ image: `${process.env.REACT_APP_IMAGES}${val.photo[0].image}`, name: val.name, id: val.product_id }); showProductModal() }}
                                >
                                  Get Best Quote
                                </button>
                              </div>
                            </div>
                          </div>)}

                          <button
                            className="tp-product-details-add-to-cart-btn"
                            style={{ margin: "auto" }}
                            onClick={() => navigate("/corporate-videos")}
                          >
                            Watch More Videos
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>}


        {/* Subscribe banner */}
        <section className="tp-subscribe-area pt-70 pb-65 theme-bg p-relative z-index-1">
          <div className="tp-subscribe-shape">
            <img
              className="tp-subscribe-shape-1"
              src="assets/img/subscribe/subscribe-shape-1.png"
              alt=""
            />
            <img
              className="tp-subscribe-shape-2"
              src="assets/img/subscribe/subscribe-shape-2.png"
              alt=""
            />
            <img
              className="tp-subscribe-shape-3"
              src="assets/img/subscribe/subscribe-shape-3.png"
              alt=""
            />
            <img
              className="tp-subscribe-shape-4"
              src="assets/img/subscribe/subscribe-shape-4.png"
              alt=""
            />
            {/* <!-- plane shape --> */}
            <div className="tp-subscribe-plane">
              <img
                className="tp-subscribe-plane-shape"
                src="assets/img/subscribe/plane.png"
                alt=""
              />
              <svg
                width="399"
                height="110"
                className="d-none d-sm-block"
                viewBox="0 0 399 110"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.499634 1.00049C8.5 20.0005 54.2733 13.6435 60.5 40.0005C65.6128 61.6426 26.4546 130.331 15 90.0005C-9 5.5 176.5 127.5 218.5 106.5C301.051 65.2247 202 -57.9188 344.5 40.0003C364 53.3997 384 22 399 22"
                  stroke="white"
                  strokeOpacity="0.5"
                  strokeDasharray="3 3"
                ></path>
              </svg>
              <svg
                className="d-sm-none"
                width="193"
                height="110"
                viewBox="0 0 193 110"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1C4.85463 20.0046 26.9085 13.6461 29.9086 40.0095C32.372 61.6569 13.5053 130.362 7.98637 90.0217C-3.57698 5.50061 85.7981 127.53 106.034 106.525C145.807 65.2398 98.0842 -57.9337 166.742 40.0093C176.137 53.412 185.773 22.0046 193 22.0046"
                  stroke="white"
                  strokeOpacity="0.5"
                  strokeDasharray="3 3"
                ></path>
              </svg>
            </div>
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-7">
                <div className="tp-subscribe-content">
                  {/* <span>Sale 20% off all store</span> */}
                  <h3 className="tp-subscribe-title">
                    Subscribe our newsletter
                  </h3>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5">
                <div className="tp-subscribe-form">
                  <div>
                    <form onSubmit={handleNewsSubmit}>
                      <div className="tp-subscribe-input">
                        <input type="email" placeholder="Enter Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <button>Subscribe</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Home;
